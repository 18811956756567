import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'


export const FullModSection = styled.section`
    padding:${sizes.margin * 2}px ${sizes.mediumMargin}px;
    margin:0;
    background-color:${props => props.backgroundProp || colors.slate};
    position:relative;
    width: 100%;
    ${maxW[2]} {
        width:calc(100% - ${sizes.padding}px);
        margin: 0 auto;
        padding:${sizes.margin * 2}px ${sizes.margin / 2}px;
    }
`;

export const QuarterContainer = styled.div`
    height:100%;
    /* -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);*/
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    opacity:.15;
`;
export const FullModP = styled.p`
    font-family: ${fonts.escrowRegular}
    font-size:32px;
    color:${colors.purple};
    max-width: 530px;
`;
