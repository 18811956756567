import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import logo from '../react.svg';
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import { 
  MainContainer,
  MaxWidthContainer,
  MidWidthContainer, 
  D
} from './styled/Maincss'
import Header from './partials/Header'
import Hero from './partials/IdeaHero'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import NewsletterSignup from './partials/NewsletterSignup'
import Footer from './partials/Footer'
import { getLanguageCode } from '../language'
import { maxW } from '../mixins/breakpoints'
import styled from 'styled-components'

class Donate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allIdeas:[], pageInfo:{}, lang:{}, isActive:true, thisUrl:''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        //console.log("Idea Did Mount")
        const lang = getLanguageCode()
        
        this.setState({lang:lang, thisUrl:window.location.href})
        //console.log("THE Slug: ", slug)

        Axios.get(`https://fair-play-api.webworldnow.net/donate/`)
            .then((response) => {
                this.setState({pageInfo: response.data, isActive:true})
            })
            .catch((response) => {
                console.error("aOOPS: ", response)
            })
    
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            const lang = getLanguageCode()
            this.setState({lang:lang})
            console.log("language changed: ", this.state.lang)
        }
    }
  render() {
    
    const p = this.state.pageInfo
    const l = this.state.lang

    console.log("P:::: ", p)
    const pageTitle = p[`page_title_${l}`]
    const pageSubheader = p[`page_subheader_${l}`]
    const pageContent = p[`page_content_${l}`]
    const seoTitle = p[`seo_title_${l}`]
    const seoDescription = p[`seo_description_${l}`]
    const ctaTitle = p[`donate_cta_title_${l}`]
    const mainImage = p[`main_image`]
    
    return (
        <MainContainer
            className="Donate"
            id="Donate"
            isActive={this.state.isActive}
        >
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={`${seoDescription}`} />
                <meta name="theme-color" content="#008f68" />
                <body class="dark" />
            </Helmet>
            <Header lang={l} />
            <Hero
                lang={l}
                title={pageTitle}
                subheader={pageSubheader}
                image = {mainImage}
                donate={true}
                linkTitle={ctaTitle}
            />
            <MaxWidthContainer 
                id="Mid-width-containerr"
                marginTopProp="160px"
            >
                <DContent>{ReactHtmlParser(pageContent)}</DContent>
                
            </MaxWidthContainer>
            
            <NewsletterSignup
                lang={l}
            />
            
            <Footer lang={l} />
        </MainContainer>
    );
  }
}

export default Donate

const DContent = styled(D)`
    ${maxW[1]} {
        padding:0 20px;
    }
`


