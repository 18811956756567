import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import siasize from '../../../mixins/siasize'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'

export const LuxurySectionContainer = styled.section`
    padding: 0 50px;
    max-width:1100px;
    margin:auto;
	margin-top:${props => props.marginTopProp || (sizes.margin * 2)}px;
    margin-bottom:${props => props.marginBottomProp || (sizes.margin * 2)}px;
    position:relative;
    min-height: ${props => props.minHeightProp ? props.minHeightProp : '270px'};
    overflow-x: visible;
    ${maxW[1]} {
        width:calc(100% - ${sizes.margin}px);
        padding:20px;
    }
`;
export const LuxurySectionContainer2 = styled.section`
    width:100%;
    margin:auto;
    margin-bottom:${props => props.marginBottomProp || (sizes.margin * 2)}px;
    position:relative;
    ${maxW[1]} {
        width:calc(100% - ${sizes.margin}px);
        padding:20px ${sizes.margin / 2}px;
    }
`;
export const ContentHolder = styled.div`
    
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: absolute;
    left: -13%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: .15;
    ${maxW[1]} {
        bottom:auto;
        top:0;
        left: -63%;
    }
`;

export const VerticalCenterContainer = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 86px;
    ${maxW[1]} {
        margin-top:-40px;  
    }
`;

export const Content = styled.div`
     ${siasize('font-size', 22, 26, 768, 1200)};
    line-height:1.8em;
    font-family:${fonts.escrowRegular};
    color:${props => props.colorProp || colors.black};
	width:${props => props.widthProp ? props.widthProp : '70%'};
	max-width:${props => props.maxWidthProp ? props.maxWidthProp : '100%'};
    p {
        max-width:${props => props.maxWidthProp ? props.maxWidthProp : '100%'};
    }
    ${maxW[1]} {
        width:100%;
        margin-top: ${sizes.margin}px;
    }
    .anchor-point {
        margin-top:-100px;
        padding-top:100px;
    }
`;
export const CTAHolder = styled.div`
    width:70%;
    text-align:right;
    right:30px;
    position: relative;
    margin-top: 40px;
    ${maxW[1]} {
        width:100%; 
    }
`;
