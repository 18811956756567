/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const QuarterCircle = ({fillProp}) => {
	return (

        <svg 
            css={`
                width:100%;
                height:100%;
            `}
            xmlns='http://www.w3.org/2000/svg' 
            viewBox='0 0 537 563'>
            <path 
                fill={fillProp}
                fillRule='evenodd' 
                d='M0 560V0h537c-3.516 174.659-51.56 310.255-144.128 406.789C300.302 503.323 169.345 554.393 0 560z'
            />
        </svg>
    )
}

export default QuarterCircle