import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import siasize from '../../../mixins/siasize'
import { maxW } from '../../../mixins/breakpoints'

export const HeroOuterContainer = styled.section`
	width:100%;
	padding-top:200px;
	min-height:300px;
	
	${maxW[1]}  {
		min-height:0;
		height:auto;
		margin-bottom: 20px;
    }
`;
export const HeroInnerContainer = styled.div`
	/* width: ${sizes.maxPercentContent}; */
	max-width:${sizes.maxWidthContentpx};
	height:100%;
    display:flex;
	align-items:flex-start;
	justify-content: space-between;
	color:${colors.white};
	margin:auto;
    padding: 0 50px;
	${maxW[1]} {
		width:calc(100% - 50px);
		padding:${sizes.padding / 2}px;
		flex-direction: column-reverse;
    }
`;
export const HeroLeft = styled.div`
	max-width: ${props => props.size === 'full' ? '100%' : '60%'};
    ${maxW[1]}  {
        max-width: 100%;
    }
`;
export const H1 = styled.h1`
	color:${colors.gray};
	text-transform:uppercase;
	font-family:${fonts.pangramBold};
	font-weight: 700;
	font-style: normal;
`
export const HeroP = styled.p`
	color:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	${siasize('font-size', 26, 40, 560, 1400)};
`;

export const HeroRight = styled.div`
	position:relative;
	max-width: 30%;
	width:30%;
	${maxW[1]} {
		display:none;
	}
`;
