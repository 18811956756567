import React from 'react';
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import ContactHeroSVG from './svgs/ContactHeroSVG'


import logo from '../react.svg';
import { 
  MainContainer, 
  MaxWidthContainer, 
  VerticalSpacer 
} from './styled/Maincss'

import Header from './partials/Header'
import Hero from './partials/Hero'
import Footer from './partials/Footer'
import LuxurySection from  './modules/LuxurySection'
import { Content } from './styled/modules/luxurySectioncss'
import { minW, maxW } from '../mixins/breakpoints'
import { siasize } from '../mixins/breakpoints'

import NewsletterSignup from './partials/NewsletterSignup'

import sizes from '../mixins/sizes'
import colors from '../mixins/colors'

import { getLanguageCode, avaliableLanguages } from '../language'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageInfo:{}, lang:{}
    }
  }
  componentDidMount() {
    // console.log("About Did Mount")
    const lang = getLanguageCode()
    this.setState({lang:lang})
    window.scrollTo(0, 0);

    Axios.get('https://fair-play-api.webworldnow.net/contact/')
    .then((response) => {
       console.log("res: ", response)
      this.setState({pageInfo: response.data})
    }).catch(function(error) {
      console.log("error: ", error)
    })
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log("about updated")
		// console.log("this.Props in updated about: ", this.props)
		// console.log("PrevProps in about: ", prevProps)
    // console.log("PrevState in about: ", prevState)
    // console.log("It changed languages in componentDidUpdate")


      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
      }
  }
  render() {
     console.log("STATE: ", this.state)
    let p = this.state.pageInfo
    let l = this.state.lang
    
    const pageTitle = p[`page_title_${l}`]
    const pageSubheader = p[`page_subheader_${l}`]
    const pageContent = p[`page_content_${l}`]
    const formHeader = p[`form_header_${l}`]
    const heroImage = p['hero_image']
    const seoTitle = p[`seo_title_${l}`]
    const seoDescription = p[`seo_description_${l}`]

    console.log("l: ", l)
    //console.log("THis.pageInfo: ", this.pageInfo)
    return (
      <Container className="About" id="about">
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={`${seoDescription}`} />
          <meta name="theme-color" content="#008f68" />
          <body class="dark" />
        </Helmet>
        {l && 
           <Header lang={l}/>
        }
        <Hero 
          lang={l}
          title={pageTitle}
          subheader={pageContent}
          heroImage = {<ContactHeroSVG />}
        />
        <LuxurySection 
          pageContent={pageSubheader}
          hasGraphic={false}
          colorProp={colors.slate}
          minHeightProp='0'
          isContact={true}
          maxWidthProp='680px'
        />
        {formHeader && 
          <ContactFormContainer margin={sizes.marginpx}>
             {/*} <ThisContent>{formHeader}</ThisContent>*/}
              <iframe src="https://cbgrantprogram.formstack.com/forms/contact_form" title="Contact Form" width="600" height="400"></iframe>
          </ContactFormContainer>
        }
        {formHeader && 
          <Footer lang={l} />
        }
      </Container>
        
    );
  }
}

export default Contact;

const Container = styled(MainContainer)`
  #luxury-section-container {
    margin-bottom:0 !important;
  }
`

const ContactFormContainer = styled(MaxWidthContainer)`
  width: 100%;
  margin-top: -100px !important;
  padding:0 50px;
  ${maxW[1]} {
      margin-top:-150px;
  }
  iframe {
    height: 938px;
    border: 0;
  }
`
const ThisContent = styled(Content)`
  color:${colors.slate};
  ${maxW[1]} {
      width:calc(100% - 50px) !important;
      margin:auto;
  }
`
const Iframe = styled.iframe`
  width: 100%;
`