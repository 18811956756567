import App from './App'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import React from 'react'
import { hydrate } from 'react-dom'

const userLocation = window._USER_LOCATION;
const favoritePlace = window._USER_FAV_PLACE;
delete window._USER_LOCATION;
delete window._USER_FAV_PLACE;


hydrate(
  <BrowserRouter>
    <App userLocation={userLocation} favoritePlace={favoritePlace} />
  </BrowserRouter>,
  document.getElementById('root')
);


if (module.hot) {
  module.hot.accept();
}
