import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import SmallCTA from '../ui/SmallCTA'
import { SmallModSection, QuarterContainer, SmallModP } from '../styled/modules/SmallModcss'
import QuarterCircle from '../svgs/QuarterCircle'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'

import { Timeline, Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const SmallMod = ({ backgroundProp, content, linkTitle, lang, url  }) => {
	return (
        <SmallModSection
            backgroundProp={backgroundProp}
            id="small-mod"
        >
                <Controller>
                    <Scene
                        indicators={false}
                        triggerElement="#small-mod"
                        duration={`140%`}
                        offset={ 100}
                        triggerHook={`onCenter`}
                    >
                        {(progress, event) => {
                            return (
                                
                                <Tween
                                    from={{ scale:3.5 }}
                                    to={{ scale:1 }}
                                    reverse
                                    paused
                                    playState={
                                        
                                        (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                        (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                    }
                                >
                                    <QuarterContainer>
                                        <QuarterCircle 
                                            fillProp={colors.bergundy}
                                        />
                                    </QuarterContainer>
                                </Tween>
                            )
                        }}
                    </Scene>
                </Controller>
            <SmallModP>
                {content}
            </SmallModP>
            <SmallCTA
                linkTitle={linkTitle}
                url={url}
                colorprop={colors.purple}
                lang={lang}
            />
            
        </SmallModSection>
    )
}

export default SmallMod