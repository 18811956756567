import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LogoColor from '../svgs/LogoColor'
import logo from '../../images/svgs/fair-play-logo.svg'
import { 
	TopHeader,
	HeaderContainer,
	HeaderLeft,
	HeaderRight,
	NavLink,
	DonateLink,
	ActiveLang,
	LanguageSwitcher,
	LogoImg
} from '../styled/partials/headercss.js'

import { linkTitles } from '../../intl'
import { availableLanguages } from '../../language'

export default class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tempPath: "",
			langOpen:false,
		}
		//console.log("props in constructor: ", this.props)
	}
	langToggle = () => {
		this.setState({langOpen:!this.state.langOpen})
	}

	componentDidMount() {
		//console.log("header mounted")
		//console.log("LinkTitles: ", linkTitles)

		let pathname = location.pathname
		let tempPath = ""
		let languageCode = window.location.pathname.split('/')[1];
		if(pathname.includes(languageCode)) {
			tempPath = pathname.replace(`/${languageCode}`, ``)
		}
		this.setState({tempPath:tempPath})
		//console.log("Props in header mounted: ", this.props)

	}

	componentDidUpdate(prevProps, prevState) {
		// console.log("header updated")
		// console.log("this.Props in updated header: ", this.props)
		// console.log("PrevProps in header: ", prevProps)
		// console.log("PrevState in header: ", prevState)
    // if(prevProps.location.pathname !== this.props.location.pathname) {
    //   const lang = getLanguageCode()
    //   this.setState({lang:lang})
    // }
  }

  render() {

		const ideas = linkTitles.ideas[`${this.props.lang}`]
		const contact = linkTitles.contact[`${this.props.lang}`]
		const about = linkTitles.about[`${this.props.lang}`]
		const services = linkTitles.services[`${this.props.lang}`]
		const donate = linkTitles.donate[`${this.props.lang}`]
		const covid = linkTitles.covid[`${this.props.lang}`]
		const l = this.props.lang || 'en'


    return (
      <TopHeader>
      	<HeaderContainer>
	      	<HeaderLeft>
				<Link to="/en">
				<LogoImg src={logo} />
				</Link>
				<nav>
					<NavLink to={`/${l}/ideas`}>{ideas}</NavLink>
					<NavLink to={`/${l}/about`}>{about}</NavLink>
					<NavLink to={`/${l}/services`}>{services}</NavLink>
					<NavLink to={`/${l}/contact`}>{contact}</NavLink>
					<NavLink to={`/${l}/covid-19-resources`} className="emphasis">{covid}</NavLink>
				</nav>
	      	</HeaderLeft>
					{/*<h3>Lang: {this.props.lang}</h3>*/}
	      	<HeaderRight>
				 {donate && <DonateLink to={`/${l}/donate`}>{donate}</DonateLink>}
			  	<ActiveLang
				  	onClick={this.langToggle}
				>{l == "en" && "EN"}{l == "es" && "ES"}{l == "fr" && "FR"}
				</ActiveLang>
	      		{this.state.langOpen &&
				  	<LanguageSwitcher>
						{l !== "en" &&
							<NavLink 
								to={`/en${this.state.tempPath}`}
								onClick={this.langToggle}
							>EN</NavLink> 
						}
						{l !== "es" && 
							<NavLink 
								to={`/es${this.state.tempPath}`}
								onClick={this.langToggle}
							>ES</NavLink>
						}
						{l !== "fr" && 
							<NavLink 
								to={`/fr${this.state.tempPath}`}
								onClick={this.langToggle}
							>FR</NavLink> 
						}
					</LanguageSwitcher>
				  }
	      	</HeaderRight>
	      	</HeaderContainer>
      </TopHeader>
    );
  }
}

