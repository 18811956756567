import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import LittleArrowCTA from '../svgs/LittleArrowCTA'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import fonts from '../../mixins/fonts'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const SmallCTA = ({ colorprop, linkTitle, url, lang }) => {
	return (
        
        <Link
            css={`
            font-family:${fonts.pangramRegular};
            width:70%;
            line-height:1.8em;
            color:${colorprop || colors.white};
            position: relative;
            `}
            to={`/${lang}/${url}`}
        >
            <span
                css={`

                    display: inline-block;
                    position: relative;
                    height: 12px;
                    width: 12px;
                    margin-right: 5px;
                    position: relative;
                    left: 0;
                    transform: rotate(180deg);
                    top:17px;
                `}
            >
                <QuarterCircleBig
                    fillProp={colorprop}
                />
            </span>
            {linkTitle}
            <span
                css={`
                    display: inline-block;
                    width: 20px;
                    position: relative;
                    top: 2px;
                    left: 5px;
                `}
            >
                <LittleArrowCTA
                    fillProp={colorprop}
                />
            </span>
            <span
                css={`
                    display: inline-block;
                    height: 30px;
                    top: 0;
                    width: 29px;
                    position: absolute;
                    right: -28px;
                `}
            >
            </span>
        </Link>

    )
}

export default SmallCTA
