export const breakpoints = [520, 768, 880, 1200, 1500]
export const fontBreakpoints = [480, 560, 640, 720, 880, 1000, 1168, 1300, 1450, 1600]

export const minW = breakpoints.map(
    bp => `@media (min-width: ${bp}px)`
)

export const maxW = breakpoints.map(
    bp => `@media (max-width: ${bp}px)`
)

export const fontB = fontBreakpoints.map(
    fb => `@media (max-width: ${fb}px)`
)
