import QuarterCircleBig from '../svgs/QuarterCircleBig'
import IceTopper from '../ui/IceTopper'
import LazyLoad from 'react-lazyload';

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../mixins/colors'
import sizes from '../../mixins/sizes'
import { breakpoints, maxW } from '../../mixins/breakpoints'
import fonts from '../../mixins/fonts'
import { siasize, backgroundImageFilter } from '../../mixins/globals'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const IdeaCard = ({ 
    backgroundImg,
    title,
    linkText,
    subheader,
    slug,
    lang, 
    cat,
    key,
    theme,
    backgroundColorProp,
    tertiaryColor
}) => {
    // console.log("backgroeundImg in ideacard: ", backgroundImg)
    // console.log("title in ideacard: ", title)
    
	return (
        <IdeaContainer
            theme={theme}
            to={`/${lang}/ideas/${slug}`}
        >
            <IceTopper
                cat={cat}
                backgroundProp={colors.slate}
                colorprop={colors.white}
            />
            <LazyLoad offset={100}>
                <ImageContainer
                    theme={theme}
                    css={`
                        background-image:url(${backgroundImg})
                    `}
                />
            </LazyLoad>
            <CardTitle
                theme={theme}
                backgroundColorProp={backgroundColorProp}      
            >{title}
            {theme === "picless" && subheader &&
                 <CardSubheader>{subheader}</CardSubheader>
            }
            </CardTitle>
            {theme === "picless" &&
                <BottomDiv
                    tertiaryColor={tertiaryColor}
                >
                </BottomDiv>
            }
        </IdeaContainer>
    )
}

export default IdeaCard

//// CSS /////


const IdeaContainer = styled(Link)`
    width:${props => props.theme === "picless" ? "63%" : "30%"};
    max-width:${props => props.theme === "picless" ? "700px" : "360px"};
    margin: 0 auto ${sizes.marginpx};
    ${maxW[1]} {
        width: 100%;
    }
`;
const ImageContainer = styled.div`
    ${backgroundImageFilter};
    margin:0 auto;
    position:relative;
    width: 100%;
    padding-top:${props => props.theme === "short" ? "78%" : "120%"};
    background-color:${colors.peach};
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    display: ${props => props.theme === "picless" ? "none" : "block"};
    ${maxW[2]} {
        margin: 0 auto;
    }
`;
const QuarterContainer = styled.div`
    height:100%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);*/
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    opacity:.15;
`;
const CardTitle = styled.p`
    font-family: ${fonts.escrowRegular};
    margin:auto;
    padding:${props => props.theme === "picless" ? '30px' : '20px'};
    ${siasize("font-size", 22, 28, 480, 1200)};
    /*font-size:${props => props.theme === "picless" ? "32px ": "inherit" };*/
    color:${props => props.theme === "picless" ? colors.white : colors.black};
    background-color: ${props => props.theme === "picless" ? props.backgroundColorProp : "transparent"};
    height:${props => props.theme === "picless" ? "57%" : "auto"};
    position: relative;
    text-align:${props => props.theme === "picless" ? "left" : "center"};
    ${maxW[1]} {
        min-height:${props => props.theme === "picless" ? "280px" : "auto"};
    }
`;
const CardSubheader = styled.p`
    ${siasize('font-size', 18, 20, 600, 1200)};
`
const BottomDiv = styled.div`
    background-color:${props => props.tertiaryColor ? props.tertiaryColor : "transparent"};
    padding:${props => props.theme === "picless" ? '30px' : '20px'};
    ${siasize("font-size", 12, 17, breakpoints[0], breakpoints[8])};
    text-align: center;
    color:${props => props.backgroundColorProp || colors.black};
    ${maxW[1]} {
        min-height:20px;
    }
`;
