import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'


export const NewsletterContainer = styled.div`
    background-color:${colors.peach2};
    padding:${sizes.margin}px;

    ${maxW[1]}  {
        max-width: 100%;
        display:flex;
        padding:${sizes.padding / 2}px;
        
    }
`;
export const LeftP = styled.p`
    width:100%;
    padding:${sizes.margin / 2}px;
    font-family:${fonts.escrowRegular};
    font-size:26px;
    line-height:.03em;
    font-size: 26px;
    line-height: 1.3em;
    max-width: 500px;
`;
export const FormHolder = styled.div`
    width:100%;
    padding:${sizes.margin / 2}px;
    max-width: 440px;
`;
export const Input = styled.input`
    width: calc(100% - 130px);
    padding:9px 10px;
`;
export const buttonStyle = `
    -webkit-appearance:none;
    appearance:none;
    -moz-appearance:none;
    display:inline-block;
    border: none;
    position: relative;
    top: -1px;
    text-align: left;
    padding: 10px 6px 10px 12px;
    text-transform:uppercase;
    background-color:${colors.bergundy};
    color:${colors.white};
    font-size:12px;
    &:after {
        display:inline-block;
        content:"";
    }
`
export const Button = styled.a`
   ${buttonStyle}
`;
export const SVGHolder = styled.span`
	display: inline-block;
    height: 34px;
	top: 0;
	width: 30px;
	position: absolute;
	right: -30px;
	/*-webkit-transform: rotate(272deg);
	-ms-transform: rotate(272deg);
	-webkit-transform: rotate(272deg);
	-ms-transform: rotate(272deg);
	transform: rotate(272deg);*/
`;
