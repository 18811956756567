
import { 
    FeaturedIdeaContainer, 
    FeaturedIdeaContent, 
    FeaturedImageContainer,
    FeaturedCardTitleHolder,
    FeaturedCardContent,
    FeaturedCardTitle,
    FeaturedBottomDiv
    
} from '../styled/modules/FeaturedIdeaCardcss'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import IceTopper from '../ui/IceTopper'
import sizes from '../../mixins/sizes'
import { siasize, backgroundImageFilter } from '../../mixins/globals' 
import colors from '../../mixins/colors'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const FeaturedIdeaCard = ({ backgroundImg, title, subtitle, linkText, slug, lang, cat, key, theme, backgroundColorProp, tertiaryColor }) => {
    console.log("backgroendImg in ideacard: ", backgroundImg)
    console.log("title in ideacard: ", title)
    
	return (
        <div>

            
            <FeaturedIdeaContainer
                theme={theme}
                to={`/${lang}/ideas/${slug}`}
            >
                <IceTopper
                    cat={cat}
                    backgroundProp={colors.gray}
                    colorprop={colors.white}
                />
                <FeaturedIdeaContent>
                    <FeaturedImageContainer
                        theme={theme}
                        css={`
                            background-image:url(${backgroundImg});
                            ${backgroundImageFilter}
                        `}
                    />
                    <FeaturedCardTitleHolder>
                        <FeaturedCardTitle
                            theme={theme}
                            backgroundColorProp={backgroundColorProp}
                        
                        >{title}
                        </FeaturedCardTitle>
                        <FeaturedCardContent>
                            {subtitle}
                        </FeaturedCardContent>
                    </FeaturedCardTitleHolder>
                    <FeaturedBottomDiv
                        tertiaryColor={tertiaryColor}
                    >
                    </FeaturedBottomDiv>
                </FeaturedIdeaContent>
            </FeaturedIdeaContainer>
        </div>
    )
}

export default FeaturedIdeaCard