import { paths } from './intl'
export const availableLanguages = [
    "en", "es", "fr"
]
export const getLanguageCode = () => {
    const path = window.location.pathname.split('/')[1]

    
    let languageCode = window.location.pathname.split('/')[1];
    var foundLanguage = false;
    if(paths.includes(languageCode)) {
        console.log("THE PATH INCLUDES THE LANGUAGE CODE: ", languageCode)
        foundLanguage = false;
    } else {
        foundLanguage = availableLanguages.includes(languageCode);
    }
    
    console.log("languageCode: ", languageCode)
    console.log("foundLanguage: ", foundLanguage)
    if(!foundLanguage) {
        languageCode = "en"
    }
    return languageCode
}