import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import { 
    MainContainer, 
    MidWidthContainer, 
    VerticalSpacer, 
    AccentBar,
    MarginBreaker,
    SizeRegulator,
    P, D
  } from './styled/Maincss'

import { 
    HeroOuterContainer,
    HeroInnerContainer,
    HeroLeft,
    HeroRight, 
    H1 

} from './styled/partials/heroHomecss'

import Header from './partials/Header'
import Footer from './partials/Footer'
import ServiceHero from './partials/ServiceHero'
import Tangle from './partials/Tangle'

  /** @jsx jsx */
import { css, jsx } from '@emotion/core'

import styled from 'styled-components'

import siasize from '../mixins/siasize'
import colors from '../mixins/colors'
import sizes from '../mixins/sizes'
import fonts from '../mixins/fonts'
import { maxW } from '../mixins/breakpoints'

import { getLanguageCode, availableLanguages } from '../language'

class Service extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageInfo:{}, lang:{}, isActive:true, thisUrl:''
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        //console.log("Idea Did Mount")
        const lang = getLanguageCode()
    
        this.setState({lang:lang, thisUrl:window.location.href})
        let slug = this.props.match.params.slug
        console.log("THE Slug: ", slug)
    
        if(slug) {
            Axios.get(`https://fair-play-api.webworldnow.net/covid_note/${slug}/`)
                .then((response) => {
                    this.setState({pageInfo: response.data, isActive:true})
                })
                .catch((response) => {
                    console.error("aOOPS: ", response)
                })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
          const lang = getLanguageCode()
          this.setState({lang:lang})
          console.log("language changed: ", this.state.lang)
        }
    }
    render () {
        const p = this.state.pageInfo
        const l = this.state.lang
        let theIdea = ''


        const header = p[`header_${l}`]
        const teaser = p[`teaser_${l}`]
        const description = p[`description_${l}`]
        const seoTitle = p[`seo_title_${l}`]
        const seoDescription = p[`seo_description_${l}`]
        const ideaImage = p[`main_image`]
        const publishDate = p[`date_${l}`]
        const featuredIdea = p.featured_ideas || []
        const tangleTitle = p[`tangle_title_${l}`]
        const tangleLinkTitle = p[`tangle_link_title_${l}`]
        const tangleUrl = p[`tangle_url`]
        //console.log("Featued: Idea: ", featuredIdea)

        if(featuredIdea.length) {
            theIdea = featuredIdea[0]
        }
        console.log("theIdea: ", theIdea)
        return (
            <MainContainer 
                className="main-container" 
                id="service-container"
                isActive={this.state.isActive}
            >
                <Helmet>
                    <title>{seoTitle}</title>
                    <meta name="description" content={`${seoDescription}`} />
                    <meta name="theme-color" content="#008f68" />
                    <body class="dark" />
                </Helmet>
				<SizeRegulator>
					<Header lang={l} />
					<ServiceHero
						title={header}
						subheader={teaser}
						content={description}
						featuredIdea={theIdea}
						lang={l}
					/>
						{/* <Tangle
							title={tangleTitle}
							linkTitle={tangleLinkTitle}
							url={tangleUrl}
						/> */}
                </SizeRegulator>
                <Footer 
                    lang={l} 
                />
            </MainContainer>
            
        )
    }
}

export default Service

const Content = styled.div`
    font-family:${fonts.escrowRegular};
`
const Header1 = styled.h1`
	color:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	${siasize('font-size', 26, 32, 560, 1400)};
`;