import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import logo from '../react.svg';
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import { remove } from 'lodash'
import { 
  MainContainer, 
  MaxWidthContainer, 
  VerticalSpacer, 
  AccentBar,
  MarginBreaker,
  SizeRegulator,
  P
} from './styled/Maincss'
import Header from './partials/Header'
import Hero from './partials/Hero'
import IdeaCard from './modules/IdeaCard'
import FeaturedIdeaCard from './modules/FeaturedIdeaCard'
import NewsletterSignup from './partials/NewsletterSignup'
import Tangle from './partials/Tangle'
import Footer from './partials/Footer'
import colors from '../mixins/colors';
import sizes from '../mixins/sizes'
import { maxW } from '../mixins/breakpoints'
import LuxurySection from  './modules/LuxurySection2'
import styled from 'styled-components'

import IdeasHeroSVG from './svgs/IdeasHeroSVG'

import { getLanguageCode, avaliableLanguages } from '../language'
class Ideas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageInfo:{}, allIdeas:[], lang:{}, isActive:false
    }
  }
  componentDidMount() {
    console.log("Ideas Did Mount")
    const lang = getLanguageCode()
    this.setState({lang:lang})
    window.scrollTo(0, 0);

    Axios.get('https://fair-play-api.webworldnow.net/all-ideas/')
      .then((response) => {
        console.log("all ideas response: ", response)
        window.allIdeas = response.data
        this.setState({allIdeas: response.data, isActive:true })
      })
    

    Axios.get('https://fair-play-api.webworldnow.net/ideas/')
      .then((response) => {
        console.log("ideas page response: ", response)
        this.setState({pageInfo: response.data})
      })
  }
  componentDidUpdate(prevProps, prevState) {
      document.body.scrollTop
      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
       
      }
  }
  render() {
    const p = this.state.pageInfo

    const l = this.state.lang
    const pageTitle = p[`page_title_${l}`]
    const pageSubheader = p[`page_subheader_${l}`]
    const pageContent = p[`page_content_${l}`]
    const heroImage = p['hero_image']
    const seoTitle = p[`seo_title_${l}`]
    const seoDescription = p[`seo_description_${l}`]
    const tangleTitle = p[`tangle_title_${l}`]
    const tangleLinkTitle = p[`tangle_link_title_${l}`]
    const tangleUrl = p[`tangle_url`]
    const featuredIdeas = p.featured_ideas
    let allIdeas = this.state.allIdeas
    if(featuredIdeas) {
      allIdeas = remove(this.state.allIdeas, function(item) {
        return item.slug !== featuredIdeas[0].slug
      })
    } 
    console.log("PPPPPP: ", p)
    console.log("tangle url: ", p.tangle_url)
    console.log("tangle url: ", tangleUrl)
    console.log("THis.sate.isAcive: ", this.state.isActive)

    return (
      <MainContainer 
        className="Ideas" 
        id="Ideas"
        isActive={this.state.isActive}
        >
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={`${seoDescription}`} />
          <meta name="theme-color" content="#008f68" />
          <body class="dark" />
        </Helmet>
        <Header lang={l}/>
        <SizeRegulator1>
          <Hero 
            lang={l}
            title={pageTitle}
            subheader={pageSubheader}
            heroImage = {<IdeasHeroSVG />}
          />
          <ThisWrapper 
            id="featured-idea-wrapper"
            marginTopProp="160px"
          >
           <LuxurySection 
            pageContent={pageContent}
            hasGraphic={false}
            colorProp={colors.slate}
            maxWidthProp='680px'
          />
          </ThisWrapper>
        </SizeRegulator1>
        <MaxWidthContainer 
            marginTopProp="160px"
          >
          {featuredIdeas && 
            featuredIdeas.map((idea, i) => {
              return (
                <FeaturedIdeaCard
                  backgroundImg={idea.main_image}
                  title={idea[`page_title_${l}`]}
                  subtitle={idea[`page_subheader_${l}`]}
                  slug={idea.slug}
                  lang={l}
                  cat={idea['ideas_cat']}
                  backgroundColorProp={colors.bergundy}
              />
              )
            })
          }
        </MaxWidthContainer>
        <AccentBar isActive={this.state.isActive}/>
        <MarginBreaker />
        <MaxWidthContainer
          displayProp="flex"
          justifyContentProp="space-between"
          flexWrapProp="wrap"
          widthProp={`calc(100% - ${sizes.padding}px)`}
          bp={maxW[1]}
        >
        { allIdeas && 
          allIdeas.map((idea, i) => {
            return (
              <IdeaCard
                key={i}
                backgroundImg={idea.main_image}
                title={idea[`page_title_${l}`]}
                slug={idea.slug}
                lang={l}
                cat={idea['ideas_cat']}
              />
            )
          })
        }
          
      </MaxWidthContainer>
        <Tangle
          title={tangleTitle}
          linkTitle={tangleLinkTitle}
          url={tangleUrl}
        />
        <NewsletterSignup
          lang={l}
        />
        
        <Footer lang={l} />
      </MainContainer>
    );
  }
}

export default Ideas;

const SizeRegulator1 = styled(SizeRegulator)`
  min-height:calc(100vh - 350px);
`
const ThisWrapper = styled(MaxWidthContainer)`
  width:100%;
  padding:0 50px;
`