import React from 'react';
import logo from '../react.svg';
import {
	MainContainer,
	MaxWidthContainer,
	MidWidthContainer,
	SizeRegulator,
	VerticalSpacer,
	P, D
} from './styled/Maincss'
import Header from './partials/Header'
import Hero from './partials/Hero'
import { Helmet } from 'react-helmet'
import Tangle from './partials/Tangle'
import Footer from './partials/Footer'
import Megabox from './partials/Megabox'
import LuxurySection from './modules/LuxurySection'
import Block from './modules/Block'
import { Content } from './styled/modules/luxurySectioncss'
import Axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import sizes from '../mixins/sizes'
import colors from '../mixins/colors'
import fonts from '../mixins/fonts'
import { siasize } from '../mixins/globals'
import { minW, maxW } from '../mixins/breakpoints'
import styled from 'styled-components'
import ServicesHeroSVG from './svgs/ServicesHeroSVG'

import { getLanguageCode, avaliableLanguages } from '../language'

class Services extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pageInfo: {}, lang: {}
		}
	}
	componentDidMount() {
		// console.log("Services Did Mount")
		const lang = getLanguageCode()
		this.setState({ lang: lang })
		window.scrollTo(0, 0);

		Axios.get('https://fair-play-api.webworldnow.net/services/')
			.then((response) => {
				console.log("res: ", response)
				this.setState({ pageInfo: response.data })
			}).catch(function (error) {
				console.log("error: ", error)
			})
	}
	componentDidUpdate(prevProps, prevState) {
		// console.log("services updated")
		// console.log("this.Props in updated services: ", this.props)
		// console.log("PrevProps in services: ", prevProps)
		// console.log("PrevState in services: ", prevState)
		// console.log("It changed languages in componentDidUpdate")

		if (prevProps.location.pathname !== this.props.location.pathname) {
			const lang = getLanguageCode()
			this.setState({ lang: lang })
		}
	}
	render() {
		console.log("STATE: ", this.state)
		const p = this.state.pageInfo
		console.log("page info: ", p)
		const l = this.state.lang
		const pageTitle = p[`page_title_${this.state.lang}`]
		const pageSubheader = p[`page_subheader_${this.state.lang}`]
		const pageContent = p[`page_content_${this.state.lang}`]
		const heroImage = p['hero_image']
		const seoTitle = p[`seo_title_${this.state.lang}`]
		const seoDescription = p[`seo_description_${this.state.lang}`]
		const tangleTitle = p[`tangle_title_${l}`]
		const pricingTitle = p[`pricing_title_${l}`]
		const pricingContent = p[`pricing_content_${l}`]
		const tangleLinkTitle = p[`tangle_link_title_${l}`]
		const middleContent = p[`middle_content_${l}`]
		const tangleUrl = p[`tangle_url`]
		const services = p[`services_list`] || []

		return (
			<MainContainer className="Services" id="services">
				<Helmet>
					<title>{seoTitle}</title>
					<meta name="description" content={`${seoDescription}`} />
					<meta name="theme-color" content="#008f68" />
					<body class="dark" />
				</Helmet>
				<Header lang={l} />
				<Hero
					lang={l}
					title={pageTitle}
					subheader={pageSubheader}
					heroImage={<ServicesHeroSVG />}
				/>
				

					{/* <LuxurySection
						pageContent={pageContent}
						hasGraphic={false}
						colorProp={colors.slate}
						marginBottomProp="0"
						maxWidthProp={`600px`}
					/> */}
				{pageContent &&
					<>
					<Content1>{ReactHtmlParser(pageContent)}</Content1>
	
					{/* <ServicesContainer>
						{services.map((service, i) => {
							return (
								<Block
									key={i}
									header={service[`header_${l}`]}
									teaser={service[`teaser_${l}`]}
									linkTitle={service[`cta_${l}`]}
									lang={l}
									slug={service.slug}
									section={`services`}
								/>
							)
						})}
					</ServicesContainer> */}

				<Megabox
					title={pricingTitle}
					content={pricingContent}
				/>
				<Tangle
					title={tangleTitle}
					linkTitle={tangleLinkTitle}
					url={`/${l}/ideas`}
				/>
				<Footer lang={this.state.lang} />
				</>
			}
			</MainContainer>

		);
	}
}

export default Services;

const ServicesContainer = styled(MaxWidthContainer)`
  padding:50px;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width: calc(100% - 100px);
  
`
const MegaSection = styled.section`
  background:${colors.slate};
  padding:${sizes.padding * 2}px 0;
`
const H2 = styled.h2`
  color:${colors.white};
  font-family:${fonts.escrowRegular};
  text-align:center;
  ${siasize('font-size', 32, 36, 560, 1400)};
`
const P1 = styled(P)`
  color:${colors.white};
`
const Content1 = styled(Content)`
  width:calc(100% - 100px);
  max-width:1100px;
  padding:0 50px;
  margin:auto;
  margin-bottom:100px;
  ${maxW[1]} {
    width: calc(100% - 100px);
	margin: 115px auto;
  }
`