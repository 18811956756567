import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'


export const SquareModSection = styled.section`
    padding:${sizes.bigVertMargin}px ${sizes.mediumMargin}px;
    margin:${sizes.margin}px ${sizes.margin / 2}px ${sizes.margin}px 0;
    background-color:${props => props.backgroundProp || colors.slate};
    position:relative;
    width: calc(100% - 100px);
	overflow:hidden;
    ${maxW[1]} {
        width:calc(100% - ${sizes.padding}px);
        margin: 0 auto;
        padding:${sizes.margin * 2}px ${sizes.padding / 2}px;
    }
`;
export const QuarterContainer = styled.div`
    width:30%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(90deg);
    position: absolute;
    top: -4px;
    right: 3px;
    display: flex;
    opacity:.15;
`;
export const SquareModP = styled.div`
    font-family: ${fonts.escrowRegular};
    font-size:32px;
    color:${colors.white};
    margin-bottom:30px;
`;
