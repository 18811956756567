import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../mixins/colors'
import sizes from '../../mixins/sizes'
import { siasize } from '../../mixins/globals'
import fonts from '../../mixins/fonts'
import { maxW, breakpoints } from '../../mixins/breakpoints'

export const MainContainer = styled.div`
	min-height:100vh;
	opacity:${props => props.isActive ? '1' : '0'}
	transition: opacity 2s;
	transition-delay: 1s;
    overflow-x: hidden;
`;
export const MaxWidthContainer = styled.div`
	width:${sizes.maxPercentContent};
	max-width:${sizes.maxWidthContentpx};
	margin:auto;
	display: ${props => props.displayProp || "block"};
	flex-wrap: ${props => props.flexWrapProp || "nowrap"};
	justify-content:${props => props.justifyContentProp || "space-between"};
	overflow: hidden;
	position: relative;
    z-index: 1;
}
	${maxW[1]} {
		margin:${sizes.margin / 2}px auto;
		flex-direction:column;
		width:${props => props.widthProp || '100%'};
    }
`;
export const MidWidthContainer = styled.div`
	width:${props => props.widthProp || sizes.maxPercentContent};
	max-width:${props => props.maxWidthProp || sizes.midWidthContentpx};
	margin:auto;
	display: ${props => props.displayProp || "block"};
	flex-wrap: ${props => props.flexWrapProp || "nowrap"};
	justify-content:${props => props.justifyContentProp || "space-between"};
	overflow: hidden;
	align-items:${props => props.alignItemsProp || 'auto'};
	${props => props.bp || maxW[3]} {
		margin:${sizes.margin / 2}px auto;
		flex-direction:column;
		width:calc(100% - 50px);
    }
`;
export const VerticalSpacer = styled.div`
	margin-top:${props => props.marginTopProp || 'auto'};
	margin-bottom:${props => props.marginBottomProp || 'auto'};
`;
export const CTA = styled(Link)`
	width:70%;
	line-height:1.8em;
	color:${props => props.colorProp || colors.white};
	position: relative;
	
`;
export const BigCTA = styled(Link)`
	color:${props => props.colorProp || colors.white};
	background-image:url('/images/littleArrow.BigCTA.png');
	background-position:right center;
	background-repeat:no-repeat;
	background-color:${props => props.backgroundProp || colors.bergundy2};
	padding: 8px 26px 8px 13px;
    text-transform: uppercase;
	font-size: 12px;
	font-family:${fonts.pangramBold};
	position: relative;
	&:after {
		content: "";
		display: inline-block;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 0;
		right: -30px;
		background-image: url(/images/grey-quarter-circle.svg);
		filter: brightness(0%);
	}
	&:before {
		content: "";
		display: inline-block;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 0;
		left: -30px;
		background-color:${props => props.backgroundProp || colors.bergundy2};
		background-image: url(/images/grey-quarter-circle.svg);
	}
`;
export const AccentBar = styled.div`
    position:relative;
    top: -13vw;
    padding-top: 14%;
    width:100%;
	background-color:${colors.peach3};
	opacity:${props => props.isActive ? 1 : 0};
	transition:opacity 2s;

	${maxW[1]} {
		top: -115vw;
		padding-top: 114vw;
		
	}
	${maxW[0]} {
		top: -203vw;
		padding-top: 192vw;
	}
`;
export const MarginBreaker = styled.div`
    ${maxW[1]} {
		margin-top: -85vw;
    }
    ${maxW[0]} {
        margin-top: -172vw;
    }
`;
export const P = styled.p`
	${siasize('font-size', 18, 20, 560, 1200)};
	font-family:${fonts.escrowRegular};
	line-height: 166%;
`
export const D = styled.div`
	${siasize('font-size', 18, 20, 560, 1200)};
	color:${colors.black};
	font-family:${fonts.escrowRegular};
	line-height: 166%;
	margin-bottom:150px;
	h2, h3, h4, h5, h6 {
		font-family:pangramBold;
		color:${colors.bergundy};
	}
	sub {
		font-size: 12px;
		position: relative;
		top: -8px;
	}
	a[name] {
		padding-top: 100px;
		margin-top: -100px;
		display: inline-block; /* required for webkit browsers */
		font-size: 12px;
		position: relative;
		top: -10px;
	}
	blockquote {
		p {
			color:${colors.bergundy};
			font-family:${fonts.pangramBold};
			position:relative;
			&:before {
				position: absolute;
				content: ",,";
				font-size: 200px;
				font-family: "escrow-banner",Times,serif;
				opacity: .1;
				top: 76px;
				left: -35px;
				transform:rotate(180deg);
				letter-spacing: -15px;
			}
		}
	}
`

export const SizeRegulator = styled.div`
  	min-height:100vh;
`;

const BigWideContainer = styled.div`
    max-width:1700px;
    margin:auto;
`
