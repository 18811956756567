import React from "react";
import styled from "styled-components"
import sizes from '../../mixins/sizes'
import { maxW } from '../../mixins/breakpoints'

import { Timeline, Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const StyledSVG = styled.svg `

    width: 723px;
    position: absolute;
    top: -190px;
    left: -130px;

    ${maxW[2]} {
        width: 330px;
    }

    ${maxW[1]} {
        width: 60px;
    }
`

const HomepageHeroSVG = () => {
  return (
    <>
    <Controller>
        <Scene
			indicators={false}
			triggerElement="#hero"
			duration={3}
			triggerHook='onLeave'
			offset={100}
		>
		{(progress, event) => {

			return (
				<>
					<StyledSVG
						xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 788 2561"
						preserveAspectRatio="xMidYMid meet"
					> <g id="Home" transform="translate(144.000000, 148.000000)" fillRule="nonzero">
                        <Timeline>
						<Tween
							position="0"
                            from={{ y: 30, }}
                            to={{ y: -50, }}
							reverse
							paused
							playState={
								(event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
								(event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
							}
						>    
							<path
								fill="#E39970"
								d="M264 664V386H0c1.729 86.706 25.347 154.02 70.856 201.942C116.366 635.864 180.746 661.217 264 664z"
							/>
						</Tween>
						<Tween
							position="0"
                            from={{ y: 50, }}
                            to={{ y: -100, }}
							reverse
							paused
							playState={
								(event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
								(event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
							}
						>    
							<path
								style={{ mixBlendMode: "multiply" }}
								fill="#9E2A4C"
								d="M157 564V207h343c-2.246 111.345-32.933 197.788-92.06 259.328C348.813 527.868 265.167 560.426 157 564z"
							/>
						</Tween>
						<Tween
							position="0"
                            from={{ y: 30, }}
                            to={{ y: -50, }}
							reverse
							paused
							playState={
								(event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
								(event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
							}
						>    
							<path
								style={{ mixBlendMode: "multiply" }}
								fill="#616982"
								d="M71 0v307h293c-1.919-95.75-28.132-170.086-78.64-223.007C234.852 31.07 163.4 3.073 71 0z"
							/>
						</Tween>
                        </Timeline>
                        </g>
					</StyledSVG>
				</>
			)
		}}
        </Scene>
      </Controller>
    </>
  );
}

export default HomepageHeroSVG;

