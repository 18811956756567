
import React from 'react'
import styled from 'styled-components'
import iconPrint from '../../images/print.png'
import iconMail from '../../images/mail.png'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    PocketShareButton,
    InstapaperShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    RedditIcon,
    TumblrIcon,
    LivejournalIcon,
    MailruIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    EmailIcon,
  } from 'react-share';

const SocialShare = (props) => {

    return (
        <SocialContainer id="social-container">
            <FacebookShareButton
                title="Fair Play"
                url={props.thisUrl}
                quote="because things are not fair"
                className="facebook__share-button">
                <FacebookIcon size={35} round={true} />
            </FacebookShareButton>
            &nbsp; &nbsp;
            <TwitterShareButton
                title="Fair Play"
                url={props.thisUrl}
                quote="Fair Play because things are not fair"
                className="twitter__share-button">
                <TwitterIcon size={35} round={true} />
            </TwitterShareButton>
            &nbsp; &nbsp;
            <LinkedinShareButton
                title="Fair Play"
                url={props.thisUrl}
                quote="because things are not fair"
                className="linkedin__share-button">
                <LinkedinIcon size={35} round={true} />
            </LinkedinShareButton>
            &nbsp; &nbsp;
            <RedditShareButton
                title="because things are not fair"
                url={props.thisUrl}
                quote="hello"
                className="reddit__share-button">
                <RedditIcon size={35} round={true} />
            </RedditShareButton>
            &nbsp; &nbsp;
            <EmailShareButton
                 title="Fair Play"
                 url={props.thisUrl}
                 quote="because things are not fair"
                 className="email__share-button"
            >
                <PrintIcon src={iconMail} />
            </EmailShareButton>
        </SocialContainer>
    )

}

export default SocialShare



/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const PrintIcon = styled.img`
    display:inline-block;
    width:35px;
    height:35px;
    cursor:pointer;
`
const SocialContainer = styled.section`
    filter: saturate(.3);
    >div {
        display:inline-block;
        cursor:pointer;
    }
`