import React from 'react'
import { Link } from 'react-router-dom'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import fonts from '../../mixins/fonts'
import { siasize } from '../../mixins/globals'
import { maxW } from '../../mixins/breakpoints'

import { 
    MainContainer, 
    MaxWidthContainer, 
    MidWidthContainer, 
    VerticalSpacer,
    P, D
  } from '../styled/Maincss'


import styled from 'styled-components'

const Block = ({header, teaser, linkTitle, lang, slug, section }) => {
    console.log("Section", section)
    return (
        <BlockHolder>
            <Title>{header}</Title>
            <Teaser>{teaser}</Teaser>
            <BlockLink to={`/${lang}/${section}/${slug}`}>{linkTitle}</BlockLink>
        </BlockHolder>
    )
}

export default Block

/* styles */

const BlockHolder = styled.div`
    padding-bottom:70px;
    border-bottom:1px solid ${colors.bergundy};
    margin-bottom:50px;
    width:44%;
    position: relative;
    ${maxW[2]} {
		width:calc(100% - 100px);
    }

`
const Title = styled.h4`
    color:${colors.peach};
    text-transform:uppercase;
    font-family:${fonts.pangramRegular};
    ${siasize('font-size', 16, 18, 560, 1200)};
    
`
const Teaser = styled(D)`
    font-family:${fonts.escrowRegular};
    margin-bottom:30px;
	a {
		text-decoration:underline;
	}
    
`
const BlockLink = styled(Link)`
    color:${colors.bergundy};
    font-family:${fonts.pangramBold};
    ${siasize('font-size', 13, 15, 560, 1200)};
    display: inline-block;
    position: absolute;
    bottom: 30px;
`