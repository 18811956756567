const colors = {
	wine: '#9e2a4c',
	wine2: '#b66079',
	wine3: '#cf95a6',
	slate: '#616982',
	slate2: '#888ea1',
	slate3: '#80b4c1',
	peach: '#e39970',
	peach2: '#eab394',
	peach3: '#f1ccb8',
	peach4: '#f4e9e3',
	black: '#1c0a17',
	gray: '#52454f',
	gray2: '#8a8087',
	gray3: '#bfbabd',
	gray4: '#f5f5f5',
	white: '#ffffff',
	bergundy: '#610817',
	bergundy2: '#884551',
	bergundy3: '#b0838b',
	purple: '#3d1226',
	purple2: '#6e4d5c',
	purple3: '#9e8893',
	red: 'red'
}

export default colors
