import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'
import Axios from 'axios'

import { MainContainer, MaxWidthContainer, VerticalSpacer } from './styled/Maincss'
import Header from './partials/Header'
import Hero from './partials/Hero'
import LuxurySection from './modules/LuxurySection'
import LuxurySection2 from './modules/LuxurySection2'
import SquareMod from './modules/SquareMod'
import SmallMod from './modules/SmallMod'
import FullMod from './modules/FullMod'
import IdeaCard from './modules/IdeaCard'
import logo from '../react.svg'

import HomepageHeroSVG from './svgs/HomepageHeroSVG'

import NewsletterSignup from './partials/NewsletterSignup'
import Footer from './partials/Footer'

import QuarterCircle from './svgs/QuarterCircle'
import colors from '../mixins/colors'
import sizes from '../mixins/sizes'
import { maxW } from '../mixins/breakpoints'
import { getLanguageCode } from '../language'

import styled from 'styled-components'

// import ScrollTest from './ScrollTest.jsx'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      	pageInfo:{}, lang:{}, isActive:false
    }
  }
  componentDidMount() {
    console.log("home loaded")
    const lang = getLanguageCode()
    this.setState({lang:lang})
    window.scrollTo(0, 0)

    Axios.get('https://fair-play-api.webworldnow.net/home/')
		.then((response) => {
			this.setState({pageInfo: response.data, isActive:true})
		})
  }
  componentDidUpdate(prevProps, prevState) {

      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
      }
  }
  render() {
    console.log("STATE of homepage: ", this.state)
    
    const p = this.state.pageInfo
    const l = this.state.lang

    const pageTitle = p[`page_title_${l}`]
    const pageContent = p[`page_content_${l}`]
    const pageContentLinkTitle = p[`page_content_link_${l}`]
    const pageContentLink = p[`page_content_url`]
    
    const slug = p['slug']

    const pageSubheader = p[`page_subheader_${l}`]
    //const heroImage = p['hero_image']
    const seoTitle = p[`seo_title_${l}`]
    const homeBlock1Link = p[`home_block1_link`]
    const homeBlock2Link = p[`home_block2_link`]
    const homeBlock3Link = p[`home_block3_link`]
    const seoDescription = p[`seo_description_${l}`]
    const homeBlock1LinkText = p[`home_block1_title_${l}`]
    const homeBlock2LinkText = p[`home_block2_title_${l}`]
    const homeBlock3LinkText = p[`home_block3_title_${l}`]
    const homeBlock1Content = p[`home_block_1_content_${l}`]
    const homeBlock2Content = p[`home_block_2_content_${l}`]
    const homeBlock3Content = p[`home_block_3_content_${l}`]
/*heroImage={<HomeHerosvg />*/

    //const homeBlockLink = this.state.pageInfo[`home_block_link`]
    const homeBlockLink = "Learn more"
    const featuredIdeas = p.featured_ideas
    console.log("Featured Ideas: ", featuredIdeas)

    return (
      <MainContainer className="Home" id="Home" isActive={this.state.isActive}>
        {p && 
        <div>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={`${seoDescription}`} />
          <meta name="theme-color" content="#008f68" />
          <body class="dark" />
        </Helmet>
        <Header lang={l}/>
        <Hero
          lang={l}
          title={pageTitle}
          subheader={pageSubheader}
          heroImage={<HomepageHeroSVG />}
        />
        <LuxurySection
          pageContent={pageContent}
          linkText={pageContentLinkTitle}
          link={`/${l}/${pageContentLink}`}
          hasGraphic={false}
          maxWidthProp="640px"
        />
        <MaxWidthContainer
          displayProp="flex"
          justifyContentProp="space-between"
          bp={maxW[2]}
          widthProp="100%"
        >
          {homeBlock1Content && 
            <SquareMod
              backgroundProp={colors.slate}
              content={homeBlock1Content}
              linkTitle={homeBlock1LinkText}
              url={homeBlock1Link}
              lang={l}
            />
          }
          {homeBlock2Content &&
            <SmallMod
              backgroundProp={colors.peach3}
              content={homeBlock2Content}
              linkTitle={homeBlock2LinkText}
              url={homeBlock2Link}
              lang={l}
            />
          }

        </MaxWidthContainer>
        <MaxWidthContainer
          displayProp="flex"
          justifyContentProp="space-between"
          widthProp="100%"
          bp={maxW[2]}
        >
          {homeBlock3Content &&
            <FullMod
              backgroundProp={colors.bergundy3}
              content={homeBlock3Content}
              linkTitle={homeBlock3LinkText}
              url={homeBlock3Link}
              lang={l}
            />
          }
        </MaxWidthContainer>
        <VerticalSpacer 
          marginTopProp={sizes.bigVertMarginpx}
          marginBottomProp={sizes.bigVertMarginpx}
        >
          <MaxWidthContainer
            displayProp="flex"
            justifyContentProp="space-between"
            flexWrapProp="wrap"
            widthProp={`calc(100% - ${sizes.padding}px)`}
            bp={maxW[1]}
          >
            {featuredIdeas && 
              featuredIdeas.map((idea, i) => {
                let theme = "default"
                let backgroundColorProp = ""
                let tertiaryColor = ""

                console.log("i: ", i)
                if(i === 3) {
                  theme = "short"
                } else if(i === 4) {
                  theme = "picless"
                  backgroundColorProp = colors.bergundy
                  tertiaryColor = colors.peach
                } else {
                  theme = "default"
                }
                
                return (
                  <IdeaCard
                    key={i}
                    backgroundImg={idea.main_image}
                    title={idea[`page_title_${l}`]}
                    slug={idea.slug}
                    lang={l}
                    cat={idea['ideas_cat']}
                    theme={theme}
                    backgroundColorProp={backgroundColorProp}
                    tertiaryColor={tertiaryColor}
                    subheader={idea[`page_subheader_${l}`]}
                  />
                )
              })
            }
            
          </MaxWidthContainer>
        </VerticalSpacer>
        <NewsletterSignup
          lang={l}
         />
         {/* this is so the footer doesn't show up when its not suppsoed to*/}
        {featuredIdeas && 
          <Footer 
            lang={l} 
          />}
        </div>
        }
        
      </MainContainer>
    );
  }
}

export default Home;

const SizeRegulator = styled.div`
  min-height:calc(100vh - 200px);
`;
