import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'


export const SmallModSection = styled.section`
    overflow:hidden;
    padding:${sizes.mediumMargin}px ${sizes.mediumMargin}px;
    margin: auto 0 auto ${sizes.margin / 2}px;
    background-color:${props => props.backgroundProp || colors.slate};
    position:relative;
    width: calc(100% - 100px);
    ${maxW[1]} {
        margin: 25px 0;
        width:calc(100% - ${sizes.padding}px);
        padding:${sizes.margin * 2}px ${sizes.margin / 2}px;
    }
`;

export const QuarterContainer = styled.div`
    width:30%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    opacity:.15;
`;
export const SmallModP = styled.div`
    font-family: ${fonts.escrowRegular};
    font-size:32px;
    color:${colors.purple};
    margin-bottom:30px;
`;
