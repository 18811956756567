import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TagManager from 'react-gtm-module'

import Home from './components/Home'
import About from './components/About'
import Ethos from './components/Ethos'
import Ideas from './components/Ideas'
import Idea from './components/Idea'
import Donate from './components/Donate'
import Services from './components/Services'
import Service from './components/Service'
import CovidNotes from './components/CovidNotes'
import CovidNote from './components/CovidNote'
import RaceHealth from './components/RaceHealth'
import Race from './components/Race'
import Contact from './components/Contact'
import ThankYou from './components/ThankYou'
import ThankYouNewsletter from './components/ThankYouNewsletter'
import './App.css';
import Header from './components/partials/Header.jsx'
import { useState, useEffect } from 'react'

const App = (props) => {
  const [initialized, initialize] = useState(false)

  useEffect(() =>  {
    if(!initialized) {
      const tagManagerArgs = {
        gtmId: 'GTM-WVBNT63'
      }
      console.log("tracking")
      TagManager.initialize(tagManagerArgs)
    }
    initialize(true)
    
  },[])

  return (
  <React.Fragment>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/:lang/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/:lang/about" component={About} />
      <Route exact path="/:lang/ethos" component={Ethos} />
      <Route exact path="/ethos" component={Ethos} />
      <Route exact path="/:lang/ideas" component={Ideas} />
      <Route exact path="/:lang/ideas/:slug" component={Idea} />
      <Route exact path="/ideas/:slug" component={Idea} />
      <Route exact path="/ideas" component={Ideas} />
      <Route path="/ideas/:slug" component={Idea} />
      <Route path="/:lang/ideas/:slug" component={Idea} />
      <Route exact path="/:lang/services" component={Services} />
      <Route path="/:lang/services/:slug" component={Service} />
      <Route path="/services/:slug" component={Service} />
	    <Route exact path="/:lang/covid-19-resources" component={CovidNotes} />
      <Route path="/:lang/covid-19-resources/:slug" component={CovidNote} />
      <Route exact path="/:lang/race-health" component={RaceHealth} />
      <Route exact path="/:lang/race-health/:slug" component={Race} />
      <Route path="/covid-19-resources/:slug" component={Service} />
      <Route path="/contact" component={Contact} />
      <Route path="/:lang/contact" component={Contact} />
      <Route path="/donate" component={Donate} />
      <Route path="/:lang/donate" component={Donate} />
      <Route path="/:lang/thank-you" component={ThankYou} />
      <Route path="/:lang/thank-you-newsletter" component={ThankYouNewsletter} />

    </Switch>
  </React.Fragment>
  )
};

export default App;
