import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import LittleArrowCTA from '../svgs/LittleArrowCTA'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import fonts from '../../mixins/fonts'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const IceTopper = ({ colorprop, backgroundProp, tertiaryColor, cat }) => {
	return (
        
        <div
            css={`
                font-family:${fonts.escrowRegular};
                color:${colorprop};
                background:${backgroundProp};
                padding: 6px 12px;
                font-size: 14px;
                position: relative;
                display: inline-block;
            `}
        >
            <span
                css={`
                    background-color:${tertiaryColor};
                    display: inline-block;
                    height: 38px;
                    top: 0;
                    width: 34px;
                    position: absolute;
                    right: -30px;
                    -webkit-transform: rotate(272deg);
                    -ms-transform: rotate(272deg);
                    -webkit-transform: rotate(272deg);
                    -ms-transform: rotate(272deg);
                    transform: rotate(272deg);
                    font-size:14px;
                `}
            >
                <QuarterCircleBig
                    fillProp={backgroundProp}
                />
            </span>
                {cat || 'Holly is cool'}
            <span
                css={`
                    display: inline-block;
                    height: 38px;
                    top: 0;
                    width: 34px;
                    position: absolute;
                    right: -30px;
                `}
            >
            </span>
        </div>

    )
}

export default IceTopper
