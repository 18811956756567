// converted to in-page styled components

import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import colors from '../../mixins/colors'
import sizes from '../../mixins/sizes'
import fonts from '../../mixins/fonts'
import { D } from '../styled/Maincss'
import IceTopper from '../ui/IceTopper'

import { siasize, backgroundImageFilter } from '../../mixins/globals'
import { maxW } from '../../mixins/breakpoints'

export default class ServiceHero extends Component  {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render () {
        console.log("PROPS IN Service HERO: ", this.props)
        if(this.props.featuredIdea) {
            const featuredTitle = this.props.featuredIdea[`page_title_${this.props.lang}`]
            const slug = this.props.featuredIdea.slug
            console.log("featured ititle: ", )
            
        }
		return (
			<HeroOuterContainer>
				<HeroInnerContainer featuredHero={this.props.featuredIdea.main_image}>
                    {this.props.title &&
                        <H1>{ReactHtmlParser(this.props.title)}</H1>
                    }
                    <Flexy>
                        <HeroLeft>
                            {this.props.subheader &&
                                <H2>{ReactHtmlParser(this.props.subheader)}</H2>
                            }
                            {this.props.content &&
                                <Dive>{ReactHtmlParser(this.props.content)}</Dive>
                            }
                        </HeroLeft>
                        <HeroRight>
                            {this.props.featuredIdea &&
                            <Link to={`/${this.props.lang}/ideas/${this.props.featuredIdea.slug}`}>
                                <IceTopper 
                                    cat={this.props.featuredIdea.ideas_cat}
                                    backgroundProp={colors.slate}
                                    colorprop={colors.white}
   
                                />
                                <ServiceImage
                                    css={`
                                        background-image:url(${this.props.featuredIdea.main_image}); 
                                    `}
                                />
                                 <H3>{this.props.featuredIdea[`page_title_${this.props.lang}`]}</H3>
                            </Link>
                            }
                        </HeroRight>
                    </Flexy>
                    
				</HeroInnerContainer>
			</HeroOuterContainer>
		)
	}
}

////// CSS //////
export const Dive = styled(D)`
    margin-bottom:50px;
	h3 {
		color:${colors.bergundy};
		margin-bottom:0;
	}
	p {
        margin-top:0;
        font-size:90%;
	}
	a {

		color:${colors.wine};
	}
`
export const HeroOuterContainer = styled.section`
	min-height:340px;
    width:100%;
`
export const HeroInnerContainer = styled.div`
	max-width:${sizes.maxWidthContentpx};
	height:100%;
	color:${colors.white};
    margin: 0 auto;
    padding: 200px 0 25px 50px;
    justify-content:space-between;
    ${maxW[1]} {
        width:100%;
        padding: 200px 0 25px 20px;
    }
	
`
const Flexy = styled.div`
    display:flex;
    ${maxW[1]} {
		flex-direction: column;
        width:calc(100% - 50px);
    }
`
export const HeroLeft = styled.div`
    position:relative;
    ${props => props.featuredIde ? '60%' : '100%'};
    ${maxW[1]} {
        width:100%;
    }
`
export const HeroRight = styled.div`
    padding-left: 5%;
    width: 28%;
    margin-top: 30px;
	${maxW[1]}  {
        width: calc(100% - 100px);
    }
`

export const H1 = styled.h1`
	color:${colors.gray};
	text-transform:uppercase;
	font-family:${fonts.pangramBold};
	font-weight: 700;
    font-style: normal;
    width:100%;
`
export const H2 = styled.h2`
	color:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	font-weight: 300;
    font-style: normal;
    text-transform: none;
    ${siasize('font-size', 26, 32, 560, 1200)};
`
export const H3 = styled.h3`
	color:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	font-weight: 300;
    font-style: normal;
    text-transform: none;
    text-align:center;
    ${siasize('font-size', 22, 26, 560, 1200)};
`
export const P = styled.p`
	color:${colors.bergundy};
	font-family:${fonts.pangramRegular};
    ${siasize('font-size', 15, 18, 560, 1200)};
`
const ServiceImage = styled.div`
    width: 100%;
    background-color:#dddddd;
    background-size:cover;
    padding-top:140%;
    background-position:center;
    background-color:grey;
    ${backgroundImageFilter};
`
const DateDiv = styled.div`
	font-family:${fonts.pangramRegular};
	${siasize('font-size', 12, 13, 560, 1200)};
	font-weight: 500;
	color:${colors.bergundy};

`
