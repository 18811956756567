import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import siasize from '../../mixins/siasize'

import { Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

function AboutHeroSVG() {
    // console.log('sm:', )
    const [readyToReverse, setReadyToReverse] = useState('onLeave')
    const [customOffset, toggleCustomOffset] = useState(150)

    // const testFunc = () => {
    //     console.log('hello')
    // }

    return (
        <>
            <Controller>
                <Scene
                    indicators={false}
                    triggerElement="#hero"
                    duration={`140%`}
                    triggerHook={readyToReverse}
                    offset={50}
                >
                    {(progress, event) => {
                        // console.log('progress:', progress)
                        // console.log('event:', event)
                        // if(event.scrollDirection === "FORWARD" && progress > .7 && readyToReverse === 'onLeave') {
                        //     setReadyToReverse('onEnter')
    
                        //     // console.log('ready to reverse:', readyToReverse)
                        // } else if (event.scrollDirection === "REVERSE" && progress < .7 && readyToReverse === 'onEnter') {
                        //     setReadyToReverse('onEnter')
                        //     // console.log('ready to reverse #2:', readyToReverse)
                        // }
                        return (
                            <Figure>
                                <SVG
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="788"
                                    height="1100"
                                    viewBox="0 0 64 135"
                                >
                                    {/* #1 */}
                                    <Tween
                                        from={{ x: -.5, y: 0, }}
                                        to={{ y: -10, }}
                                        // reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                            (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                        duration={1.2}
                                    >
                                        <g>
                                            <path
                                                style={{ mixBlendMode: "multiply" }}
                                                fill="#E39871"
                                                d="M15.5 9.5c1.123 31.235 26.432 49.009 48.234 47.956.252-.012.513 0 .766-.01V9.5h-49z"
                                            />
                                        </g>
                                    </Tween>

                                    {/* #2 */}
                                    <Tween
                                        position="0"
                                        from={{ y: 10, }}
                                        to={{ y: -22, }}
                                        // reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                            (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                        duration={1.4}
                                    >
                                        <g>
                                            <path
                                                style={{ mixBlendMode: "multiply" }}
                                                fill="#A95359"
                                                d="M0 22c1.467 40.346 34.523 63.303 63 61.943.328-.016.67 0 1-.012V22H0z"
                                            />
                                        </g>
                                    </Tween>

                                    {/* #3 */}
                                    <Tween
                                        position="0"
                                        from={{ y: 10, }}
                                        to={{ y: -14, }}
                                        // reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                            (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                        duration={1.4}
                                    >
                                        <g>
                                            <path
                                                style={{ mixBlendMode: "multiply" }}
                                                fill="#963127"
                                                d="M64 111C62.533 70.654 29.477 47.697 1 49.057c-.328.016-.67 0-1 .012V111h64z"
                                            />
                                        </g>
                                    </Tween>

                                    {/* #4 */}
                                    <Tween
                                        position="0"
                                        from={{ x: .5, y: 10, }}
                                        to={{ y: -28.5, }}
                                        // reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                            (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                        duration={1.2}
                                    >
                                        <g>
                                            <path
                                                style={{ mixBlendMode: "multiply" }}
                                                fill="#80889B"
                                                d="M48.5 125.5C47.377 94.265 22.068 76.491.266 77.544c-.252.012-.513 0-.766.01V125.5h49z"
                                            />
                                        </g>
                                    </Tween>
                                </SVG>
                            </Figure>
                        )
                    }}
                </Scene>
            </Controller>
        </>
    )
}

export default AboutHeroSVG;

const Figure = styled.figure`
    ${siasize('width', 240, 400, 480, 1400)}
    position:relative;
`
const SVG = styled.svg`
    position: absolute;
    width: 200%;
    top: -48px;
    left: -40%;
`