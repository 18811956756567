import ReactHtmlParser from 'react-html-parser'
import BigCTA from '../ui/BigCTA'
import QuarterCircle from '../svgs/QuarterCircle'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import { maxW } from '../../mixins/breakpoints'
import { 
    LuxurySectionContainer2,
    ContentHolder,
    Content,
    CTAHolder,
} from '../styled/modules/luxurySectioncss'

import styled from 'styled-components'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const LuxurySection = ({ pageContent, linkText, link, hasGraphic, colorProp, marginBottomProp, maxWidthProp, widthProp}) => {
	return (
        <LuxurySectionContainer2 marginBottomProp={marginBottomProp}>
            <Content
                colorProp='#616982;'
				maxWidthProp={maxWidthProp}
				widthProp={widthProp}
            >
                {ReactHtmlParser(pageContent)}
            </Content>
            <CTAHolder>
                <div>
                    {linkText && 
                        <BigCTA
                            to={link}
                            linkTitle={linkText}
                            colorprop={colors.white}
                            backgroundProp={colors.bergundy2}
                            tertiaryColor={colors.bergundy}
                        />
                    }
                </div>
            </CTAHolder>
      </LuxurySectionContainer2>
        
    )
}

export default LuxurySection