import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { siasize } from '../../../mixins/globals'
import { Link } from 'react-router-dom'
import { maxW } from '../../../mixins/breakpoints'

export const TopHeader = styled.header`
	
	height:90px;
	display: flex;
	align-items: center;
	position:fixed;
	z-index: 10000;
	background: rgba(256, 256, 256, .95);
	width:100%;
    overflow-y: hidden;
`;
export const HeaderContainer = styled.div`
	display:flex;
	max-width:1400px;
    margin:auto;
	width:100%;
	justify-content:space-between;
    width: calc(100% - 100px);
    padding:0 ${sizes.paddingpx};
   
	${maxW[1]}  {
        /* mobile nav */
        width: calc(100% - 40px);
        padding: 0 20px;
        padding-bottom: 200px;
        padding-top: 16px;
        overflow-x:scroll;
        overflow-y:hidden;
        
    }
`;
export const HeaderLeft = styled.div`
	display: flex;
    align-items: center;
`;
export const HeaderRight = styled.div`
	color:${colors.wine2};
	padding:5px 0;
	display:flex;
	align-items:center;
	position:relative;
    ${maxW[1]}  {
        padding:0 20px;
    }
`;
export const LogoImg = styled.img`
	width: 120px;
    padding-right: 20px;
    position: relative;
    top: 4px;
`

export const NavLink = styled(Link)`
	font-family: ${fonts.pangramRegular};
	color:${colors.wine};
	text-decoration: none;
	white-space:nowrap;
	margin: 0 10px;
	transition:all .3s;
	&.emphasis {
		font-size:120%;
	}
	&:hover {
		opacity:.7;
	}
`;
export const DonateLink = styled(Link)`
	background-color:${colors.bergundy};
	padding:5px 20px;
    margin-right: 10px;
	color:${colors.white};
	font-family:${fonts.escrowRegular};
	${siasize('font-size', 12, 16, 600, 1200)};
	transition:all .3s;
	&:hover {
		opacity:.7;
	}
`
export const LanguageSwitcher = styled.div`
	width: 30px;
	position: absolute;
	right: 18px;
	display: flex;
	flex-directioN: column;
	font-size: 14px;
	top: 42px;
`;
export const ActiveLang = styled.div`
	cursor:pointer;
	padding-right: 18px;
    background-image: url(/images/angle-arrow-down.svg);
    background-size: 12px;
    background-position: right;
    background-repeat: no-repeat;
`;