import React from 'react';
import logo from '../react.svg';
import { Helmet } from 'react-helmet'
import { MainContainer, MaxWidthContainer, VerticalSpacer, SizeRegulator } from './styled/Maincss'
import Header from './partials/Header'
import Hero from './partials/Hero'
import LuxurySection from  './modules/LuxurySection'
import Tangle from './partials/Tangle'
import Footer from './partials/Footer'
import Axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import sizes from '../mixins/sizes'



import { getLanguageCode } from '../language'
import colors from '../mixins/colors';


class Ethos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageInfo:{}, lang:{}
    }
  }
  componentDidMount() {
    console.log("ethos loaded")
    const lang = getLanguageCode()
    this.setState({lang:lang})
    window.scrollTo(0, 0)

    Axios.get('https://fair-play-api.webworldnow.net/ethos/')
    .then((response) => {
      console.log("res: ", response)
      this.setState({pageInfo: response.data})
    })
  }
  componentDidUpdate(prevProps, prevState) {

      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
      }
  }
  render() {
    console.log("STATE: ", this.state)

    let ethosContent
    let p = this.state.pageInfo
    let l = this.state.lang
    const pageTitle = this.state.pageInfo[`page_title_${l}`]
    const pageContent = this.state.pageInfo[`page_content_${l}`]
    const pageSubheader = this.state.pageInfo[`page_subheader_${l}`]
    const ethosList =  this.state.pageInfo.ethos_list
    const heroImage = this.state.pageInfo['hero_image']
    const seoTitle = this.state.pageInfo[`seo_title_${l}`]
    const seoDescription = this.state.pageInfo[`seo_description_${l}`]
    const tangleTitle = p[`tangle_title_${l}`]
    const tangleLinkTitle = p[`tangle_link_title_${l}`]
    const tangleUrl = p[`tangle_turl`]

    console.log("page title: ", pageTitle)
    if(ethosList) {
      ethosContent = ethosList.map((item, i) => {
        return (
          <section key={i}>
            <h2>{item[`ethos_title_${l}`]}</h2>
            <div>{item[`ethos_description_${l}`]}</div>
          </section>
        )
      })
    }
    
    console.log("ETHOS LIST: ", ethosList)
    return (
      <MainContainer className="Ethos" id="Ethos">
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={`${seoDescription}`} />
          <meta name="theme-color" content="#008f68" />
          <body class="dark" />
        </Helmet>
        <Header lang={this.state.lang}/>
        <SizeRegulator>
          <Hero
            lang={this.state.lang}
            title={pageTitle}
            subheader={pageSubheader}
            heroImage = {heroImage}
          />
          <LuxurySection 
            pageContent={pageContent}
            hasGraphic={false}
            colorProp={colors.slate}
          />
          <Tangle
            title={tangleTitle}
            linkTitle={tangleLinkTitle}
            url={tangleUrl}
          />
        </SizeRegulator>
        <Footer lang={l} />
      </MainContainer>
    );
  }
}

export default Ethos;
