import React from 'react';
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import ReactHtmlParser from 'react-html-parser'

import logo from '../react.svg';
import {
  MainContainer,
  MaxWidthContainer,
  VerticalSpacer,
  P,
  SizeRegulator
} from './styled/Maincss'

import { HeroP } from './styled/partials/heroHomecss'

import styled from 'styled-components'

import Header from './partials/Header'
import Hero from './partials/Hero'
import Tangle from './partials/Tangle'
import Megabox from './partials/Megabox'
import Footer from './partials/Footer'
import LuxurySection from  './modules/LuxurySection'
import AboutHeroSVG from './svgs/AboutHeroSVG'

import sizes from '../mixins/sizes'
import colors from '../mixins/colors'
import { minW, maxW } from '../mixins/breakpoints'

import { getLanguageCode, avaliableLanguages } from '../language'
import theFam from '../images/the-fam.jpg'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageInfo:{}, lang:{}
    }
  }
  componentDidMount() {
    // console.log("About Did Mount")
    const lang = getLanguageCode()
    this.setState({lang:lang})
    window.scrollTo(0, 0);

    Axios.get('https://fair-play-api.webworldnow.net/about/')
    .then((response) => {
       console.log("res: ", response)
      this.setState({pageInfo: response.data})
    }).catch(function(error) {
      console.log("error: ", error)
    })
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log("about updated")
		// console.log("this.Props in updated about: ", this.props)
		// console.log("PrevProps in about: ", prevProps)
    // console.log("PrevState in about: ", prevState)
    // console.log("It changed languages in componentDidUpdate")


      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
      }
  }
  render() {
     console.log("STATE: ", this.state)
    let p = this.state.pageInfo
    let l = this.state.lang
    
    const pageTitle = p[`page_title_${l}`]
    const pageSubheader = p[`page_subheader_${l}`]
	const pageContent = p[`page_content_${l}`]
	const boardContent = p[`board_${l}`]
    const foundersNoteHeader = p[`founders_note_header_${l}`]
    const foundersNoteContent1 = p[`founders_note_content_1_${l}`]
    const foundersNoteContent2 = p[`founders_note_content_2_${l}`]
    const foundersNoteContent3 = p[`founders_note_content_3_${l}`]
    const workWithUsTitle = p[`work_with_us_title_${l}`]
    const workWithUsContent = p[`work_with_us_content_${l}`]
    const heroImage = p['hero_image']
    const seoTitle = p[`seo_title_${l}`]
    const seoDescription = p[`seo_description_${l}`]
    const megaboxTitle = p[`megabox_title_${l}`]
    const megaboxContent = p[`megabox_content_${l}`]
    const tangleTitle = p[`tangle_title_${l}`]
    const tangleLinkTitle = p[`tangle_link_title_${l}`]
    const tangleUrl = p[`tangle_url`]

    console.log("AAbout: l: ", l)
    console.log("megaboxtitle: ", megaboxTitle)
    console.log("megaboxContent: ", megaboxContent)
    //console.log("THis.pageInfo: ", this.pageInfo)
    return (
      <MainContainer className="About" id="about">
        <Helmet>
			<title>{seoTitle}</title>
			<meta name="description" content={`${seoDescription}`} />
			<meta name="theme-color" content="#008f68" />
			<body class="dark" />
        </Helmet>
        {l &&
           <Header lang={l}/>
        }
        <Hero
          lang={l}
          title={pageTitle}
          subheader={pageSubheader}
          heroImage = {<AboutHeroSVG />}
        />
        <SizeRegulator>
			<LuxurySection
				pageContent={pageContent}
				hasGraphic={false}
				colorProp={colors.slate}
				maxWidthProp='680px'
				marginBottomProp='1px'
			/>
			<Board>
				<ThisMaxWidthContainer>
          <HeroP>{ReactHtmlParser("Our Team")}</HeroP>
					<P>{ReactHtmlParser(boardContent)}</P>
				</ThisMaxWidthContainer>
			</Board>

          	{/* <FounderContainer>
              <ThisMaxWidthContainer>
                <RightShiftContainer>
                   <HeroP>{ReactHtmlParser(foundersNoteHeader)}</HeroP>
                </RightShiftContainer>
                        <div>
                  <MainP>{ReactHtmlParser(foundersNoteContent1)}</MainP>
                  <Left>
                  			<Img src={theFam} alt="Holly Land founder" />
                		</Left>

                		<MainP>{ReactHtmlParser(foundersNoteContent2)}</MainP>

              		</div>
              
            	</ThisMaxWidthContainer>
          	</FounderContainer> */}

        </SizeRegulator>
        <Megabox
          title={megaboxTitle}
          content={megaboxContent}
        />
        <Tangle
          title={tangleTitle}
          linkTitle={tangleLinkTitle}
          url={`/${l}/${tangleUrl}`}
        />
        <Footer lang={l} />
      </MainContainer>
        
    );
  }
}

export default About;
const ThisMaxWidthContainer = styled(MaxWidthContainer)`
width:80%;
  ${maxW[3]} {
    width:100%;
  }
`
const Board = styled.section`
  padding:0 50px;
  ${maxW[1]} {
    padding: 0 20px;
  }
`

const BoardP = styled(P)`
  width: 85%;
  padding-left: 15%;
  ${maxW[2]} {
      width:100%;
      padding:0;
  }
`
const FounderContainer = styled.section`
  background:${colors.peach4};
  padding:${sizes.padding}px;
  ${maxW[1]} {
    padding: 30px 20px;
  }
`
const RightShiftContainer = styled.div`
  ${minW[2]} {
    padding:  0px 0 0px 15%;
  }
`
const MainP = styled(P)`
  width: 85%;
  padding-left: 15%;
  ${maxW[2]} {
      width:100%;
      padding:0;
  }
`
const Flexy = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  ${maxW[1]} {
    flex-direction:column;
  }
`
const Left = styled.div`
    float:left;
    width: calc(50% - 25px);
    margin: 20px 50px;
    ${maxW[1]} {
        width:100%;
        margin: 20px 0px;
    }
`
const Right = styled.div`
  width: calc(50% - 25px);
  ${maxW[1]} {
    width:100%;
  }
`
const Img = styled.img`
  max-width:100%;
`
