import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import BigCTA from '../ui/BigCTA'
import colors from '../../mixins/colors'

import { 
	TangleOuterContainer, 
	TangleInnerContainer,
    P,
    CTAHolder
} from '../styled/partials/Tanglecss'

export default class Tangle extends Component  {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render () {
        console.log("props in the tangle: ", this.props)
	
		return (
			<TangleOuterContainer>
				<TangleInnerContainer>
					
                    {this.props.title &&
                        <div>
                            <P>{ReactHtmlParser(this.props.title)}</P>
                            <CTAHolder>
                                <BigCTA
                                    url={this.props.url}
                                    linkTitle={this.props.linkTitle}
                                    colorprop={colors.white}
                                    backgroundProp={colors.bergundy}
                                    tertiaryColor={colors.bergundy2}
                                />
                            </CTAHolder>
                        </div>
					}
				
				</TangleInnerContainer>
			</TangleOuterContainer>
		)
	}
	
}