import React, { Component, Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { linkTitles } from '../../intl'
import colors from '../../mixins/colors'
import { footer } from '../../intl'
import QuarterCircle from '../svgs/QuarterCircle'

import { 
	FooterOuterContainer,
    FooterContainer,
    FooterLinkContainer,
	LeftContainer,
	Logo,
	Paragraph,
	RightContainer,
	FooterLinks,
	DesktopCopyright,
	MobileCopyright,
	PrivacyPolicy,
	TermsOfService,
	InfoLinksContainer,
	MobileCopyDiv,
	QuarterContainer
} from '../styled/partials/footercss.js'

import { 
	HeaderContainer
} from '../styled/partials/headercss.js'

export default class Footer extends Component {
	constructor(props){
		super(props);
		this.state = {
			tempPath: "",
		}
	}

	componentDidMount() {
		//console.log("footer mounted")

	}

	render () {
        const lang = this.props.lang;

		return(
			<React.Fragment>
				<FooterOuterContainer id="footer-outer-container">
					<FooterContainer id="footer-container">
						<QuarterContainer>
							<QuarterCircle 
								fillProp={colors.bergundy}
							/>
						</QuarterContainer>
							<LeftContainer id="left-container">
								<Logo 
									id="footer-logo"
									src={footer.logo}
								/>
								{footer.paragraph1 && <Paragraph>{ReactHtmlParser(footer.paragraph1[lang])}</Paragraph> }
								{footer.paragraph2 && <Paragraph>{ReactHtmlParser(footer.paragraph2[lang])}</Paragraph> }
								<DesktopCopyright>{footer.copyright}</DesktopCopyright>
							</LeftContainer>
							<RightContainer id="right-container">

                                {linkTitles && 
                                    <FooterLinkContainer>
                                        <FooterLinks to={`/${lang}/ideas`}>{linkTitles.ideas[`${lang}`]}</FooterLinks>
                                        <FooterLinks to={`/${lang}/about`}>{linkTitles.about[`${lang}`]}</FooterLinks>
                                        <FooterLinks to={`/${lang}/services`}>{linkTitles.services[`${lang}`]}</FooterLinks>
                                        <FooterLinks to={`/${lang}/contact`}>{linkTitles.contact[`${lang}`]}</FooterLinks>
                                    </FooterLinkContainer>
                                }
								<InfoLinksContainer id="info-links-container">
									<PrivacyPolicy to="/https://fair-play-api.webworldnow.net/wp-content/uploads/2019/10/Privacy-Policy.pdf">{footer.privacy[`${lang}`]}</PrivacyPolicy>
									<TermsOfService to="/">{footer.terms[`${lang}`]}</TermsOfService>
								</InfoLinksContainer>
								<MobileCopyDiv id="mobile-copyright-div">
									<MobileCopyright>{footer.copyright}</MobileCopyright>
								</MobileCopyDiv>	
							</RightContainer>
					</FooterContainer>
				</FooterOuterContainer>
			</React.Fragment>
		)
	}
}

