import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import BigCTA from '../ui/BigCTA'
import QuarterCircle from '../svgs/QuarterCircle'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import { maxW } from '../../mixins/breakpoints'
import { 
    LuxurySectionContainer,
    ContentHolder,
    Content,
    CTAHolder,
    VerticalCenterContainer,
} from '../styled/modules/luxurySectioncss'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const LuxurySection = ({ pageContent, linkText, link, hasGraphic, colorProp, marginBottomProp, marginTopProp, minHeightProp, isContact, maxWidthProp}) => {
	return (
        
        <LuxurySectionContainer
			marginTopProp={marginTopProp}
            marginBottomProp={marginBottomProp}
            minHeightProp={minHeightProp}
            id="luxury-section-container"
        >
            { pageContent &&
                <>

                        {hasGraphic &&
                            <ContentHolder>
                                <QuarterCircle
                                    fillProp={colors.gray3}
                                />
                            </ContentHolder>
                        }
                        <Content
                            colorProp='#616982;'
                            isContact={isContact}
                            maxWidthProp={maxWidthProp}
                        >
                            {ReactHtmlParser(pageContent)}
                        </Content>

                        <CTAHolder>
                            <div>
                                {linkText &&
                                    <BigCTA
                                        url={link}
                                        linkTitle={linkText}
                                        colorprop={colors.white}
                                        backgroundProp={colors.bergundy}
                                        tertiaryColor={colors.wine}
                                    />
                                }
                            </div>
                        </CTAHolder>
                    </>
                }
           
      </LuxurySectionContainer>

    )
}

export default LuxurySection
