import React from 'react'
import styled from 'styled-components'
import ThankYouImage from '../images/thank-you.jpg'
import { HeroP } from './styled/partials/heroHomecss'

const ThankYou = () => {
    return (
        <Container>
            <P>Someone will be in touch with you soon!</P>
            <Img src={ThankYouImage} alt="thank you" title="Thank You" />
        </Container>
    )
}

export default ThankYou

const Container = styled.div`
    min-height:800px;
    backgorund:red;
`
const P = styled(HeroP)`
    text-align:center;
`
const Img = styled.img`
    display:block;
    width: 70%;
    margin-left: 15%;
`