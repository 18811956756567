import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { minW, maxW } from '../../../mixins/breakpoints'
import { siasize } from '../../../mixins/globals'
import { Link } from 'react-router-dom'

export const FooterOuterContainer = styled.footer`
	padding: 0 ${sizes.paddingpx};
	background-color: #8c1a21;
	overflow:hidden;
	${maxW[2]} {
		padding: 0;
	}
	
`
export const FooterLinkContainer = styled.div`
	margin-top: 50px;
    ${maxW[0]} {
        display:flex;
        width:100%;
		justify-content: center;	
    }
`
export const FooterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1400px;
	margin: auto;
	position: relative;
	overflow: hidden;
	${maxW[3]} {
		overflow-x: visible;
	}
	${maxW[0]} {
        flex-direction: column;
    }
`
export const QuarterContainer = styled.div`
    position: absolute;
	display: flex;
	position: absolute;

	// ${maxW[3]} {
	// 	top:-100px;
	// 	height:
	// }




	${siasize('top', -166, -100, 560, 1200)};
	${siasize('height', 570, 580, 560, 1200)};
	${siasize('min-width', 490, 570, 560, 1200)};
    ${siasize('left', -64, -100, 560, 1200)};
	${maxW[0]} {
        display:none;
    }
`
export const LeftContainer = styled.div`
	position: relative;
	padding: 40px 0;
	overflow-x:hidden;
	margin-left: 50px;
	max-width:322px;
	${maxW[3]} {
		/*margin-left:0;*/
	}
	${maxW[2]} {
		margin-bottom: 50px;
		margin-left: 50px;
	}
	${maxW[1]} {
		margin-left: 20px;
    	width: calc(100% - 40px);
	}
`
export const Logo = styled.img`
	width: 50px;
`
export const Paragraph = styled.p`
	font-family: ${fonts.escrowRegular};
	color: ${colors.white};
	line-height: 1.6;
	${maxW[0]} {
		flex-direction: column;
		font-size: 21px;
    }
`
export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-right:0px;
	${maxW[2]} {
		padding-right:50px;
	}
	${maxW[1]} {
		padding-right:20px;
	}
`
export const FooterLinks = styled(Link)`
	font-family: ${fonts.pangramBold};
	color: ${colors.white};
	margin-bottom: 28px;
	font-size: 12px;
	text-decoration: none;
    text-align: right;
    display: block;
	${maxW[0]} {
		font-size: 16px;
        text-align:center;
        padding:12px;
	}
`
export const DesktopCopyright = styled.p`
	font-family: ${fonts.escrowRegular};
	color: ${colors.white};
	font-size: 10px;
	margin-top: 110px;
	${maxW[0]} {
        display: none;
    }
`
export const MobileCopyright = styled.p`
	font-family: ${fonts.escrowRegular};
	display: none;
	${maxW[0]} {
		display: block;
		color: ${colors.white};
		font-size: 12px;
		margin-top: 50px;
    }
`
export const InfoLinksContainer = styled.div`
	display: flex;
	${maxW[0]} {
		justify-content: center;
	}
`
export const PrivacyPolicy = styled(Link)`
	font-family: ${fonts.pangramRegular};
	color: ${colors.white};
	margin-right: 40px;
	text-decoration: none;
	font-size: 10px;
	margin-top: 50px;
	${maxW[0]} {
		font-size: 12px;
	}
`
export const TermsOfService = styled(Link)`
	font-family: ${fonts.pangramRegular};	
	color: ${colors.white};
	text-decoration: none;
	font-size: 10px;
	margin-top: 50px;
	${maxW[0]} {
		font-size: 12px;
	}
`
export const MobileCopyDiv = styled.div`
	display: none;
	${maxW[0]} {
		display: flex;
		justify-content: center;
    }
`
