import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import { siasize } from '../../../mixins/globals'
import { breakpoints, maxW, fontB } from '../../../mixins/breakpoints'
import fonts from '../../../mixins/fonts'

// width:${props => props.theme === "picless" ? "65%" : "30%"};
export const FeaturedIdeaContainer = styled(Link)`
   display:block;
    margin-bottom:${sizes.marginpx};
    ${maxW[1]} {
        width: calc(100% - ${sizes.padding}px);
        margin-left:auto;
        margin-right:auto;
        max-width: 460px;
    }
`;
export const FeaturedImage = styled.img`

`;

export const FeaturedIdeaContent = styled.div`
    display:flex;
    background-color: ${colors.purple}};
    position:relative;
    ${maxW[1]} {
        flex-direction:column;
        margin:auto;
    }
`;

export const FeaturedImageContainer = styled.div`
    margin:0 auto;
    position:relative;
    width: 40%;
    padding-top: 36%;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    z-index: 1;
    display: ${props => props.theme === "picless" ? "none" : "block"};
    ${maxW[1]} {
        margin: 0 auto;
        width: 100%;
        padding-top: 100%;
    }
`;
export const FeaturedQuarterContainer = styled.div`
    height:100%;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);*/
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    opacity:.15;
`;
export const FeaturedCardTitleHolder = styled.div`
   // display:flex;
    height:100%;
    width:100%;
    width:61%;
    padding: 30px 0;
    ${maxW[1]} {
        width:100%;
        min-height: 280px;
    }
`;
export const FeaturedCardTitle = styled.p`

    font-family: ${fonts.escrowRegular};
    font-size:32px;
    padding:0 30px;
    color:${props => props.theme === "picless" ? colors.white : colors.white};
    position: relative;
    ${siasize('font-size', 26, 32, 500, 1400)};
`;
export const FeaturedCardContent = styled.p`
    padding:0 30px;
    font-family: ${fonts.escrowRegular};
    color:${props => props.theme === "picless" ? colors.white : colors.white};
    position: relative;
    ${maxW[1]} {
    }
    ${siasize('font-size', 18, 24, 500, 1400)};
`;
export const FeaturedBottomDiv = styled.div`
    background-color:${props => props.tertiaryColor ? props.tertiaryColor : "transparent"};
    padding: 5px;
    font-size: 11px;
    text-align: center;
    color:${props => props.backgroundColorProp || colors.black};
    position: absolute;
    bottom: 0;
    right: 0;
    width: 61%;
    padding: 5px 0;
    background: ${colors.peach};
    ${maxW[1]} {
        min-height:20px;
        width:100%;
        max-width:400px;
        width: calc(100% - 60px);
        padding:5px 30px 0;
    }
    
`;

