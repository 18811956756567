export const siasize = (property, minSize, maxSize, minScreenWidth, maxScreenWidth) => {
    return `
        ${property}: calc( ${minSize}px + (${maxSize - minSize}) * ((100vw - ${minScreenWidth}px) / (${maxScreenWidth - minScreenWidth})) );
        @media screen and (min-width:${maxScreenWidth}px) {
            ${property}: ${maxSize}px;
        }
        @media screen and (max-width:${minScreenWidth}px) {
            ${property}: ${minSize}px;
        }
    `
}
export const backgroundImageFilter = `
    filter:sepia(.5);
`