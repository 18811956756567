import styled from 'styled-components'
import colors from '../../../mixins/colors'
import sizes from '../../../mixins/sizes'
import fonts from '../../../mixins/fonts'
import { maxW } from '../../../mixins/breakpoints'


export const TangleOuterContainer = styled.section`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:${sizes.padding * 2}px 0;
	${maxW[1]}  {
		min-height:0;
		height:auto;
        margin-bottom: 20px;
        display
    }
`;
export const TangleInnerContainer = styled.div`
	width: ${sizes.maxPercentContent};
	max-width: 830px;
	height:100%;
    display:flex;
	align-items:center;
	justify-content: space-between;
	color:${colors.white};
	margin:auto;
    padding: 0 50px;
	${maxW[1]} {
		width:calc(100% - 50px);
		padding:${sizes.padding / 2}px;
    }
`;
export const CTAHolder = styled.div`
    margin-left: 30px;
`;

export const P = styled.p`
	color:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	font-size:32px;
	${maxW[1]} {
		font-size: 26px;
	}
`;