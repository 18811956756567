export const paths = ["about", "ethos", "ideas"]

export const linkTitles = {
    about: {
        "en": "About",
        "es": "Sobre",
        "fr": "Oui"
    },
    ideas:{
        "en": "Ideas",
        "es": "Ideas",
        "fr": "Idi"
    },
    services:{
        "en": "Services",
        "es": "Servicios",
        "fr": "Services"
    },
    contact: {
        "en": "Contact",
        "es": "Contacto",
        "fr": "Contact"
    },
    donate: {
        "en":"Donate",
        "es":"Para Donar",
        "fr":"Faites un don"
	},
	covid: {
        "en":"COVID-19",
        "es":"COVID-19",
        "fr":"COVID-19"
    }
}

export const newsletterContent = {
    description: {
        "en": "Want to read our latest briefs, hear our newest podcasts and learn about our new projects?",
        "es": "Quieres leer lo más reciente, Oyes nuestra información y projecops en la futura",
        "fr": "THis is the french text adn we will get it right before we go live with this project, that is for sure"
    }, placeholder: {
        "en": "Enter your email to sign up",
        "es": "FEntre su email para leer ",
        "fr": "Le tigre c'est bon "
    }, ctaText: {
        "en": "Sign up",
        "es": "Firmaabajo",
        "fr": "accellente "
    }
}

export const footer = {
    logo: '/logo/Icon/PNG/FP-Logomarks_-Icon-White.png',
    paragraph1: {
        en: "Doing right does not need to come at a high cost.<br>Together we can achieve health equity at a fair price for all. ",
        es: "EEEESSSSS Doing right in healthcare does not <br>need to come at a high price.",
        fr: "FFRRRR Doing right in healthcare does not <br>need to come at a high price.",
        
    },
    pargraph2: {
        en: "",
        es: " ESSS Together we can achieve health <br>equity at a reasonable cost to all.",
        fr: " FRRRR Together we can achieve health <br>equity at a reasonable cost to all."
    },
    privacy: {
        en: "Privacy Policy",
        es: "Privacidad",
        fr: "Cconfidentialité"
    },
    terms: {
        en: "Terms of Service",
        es: "Términos de Uso",
        fr: "Conditions d'utilisation"
    },
    copyright: '© Fair Play, LLC',
}