import React from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import { siasize } from '../../mixins/globals'

import colors from '../../mixins/colors'
import sizes from '../../mixins/sizes'
import fonts from '../../mixins/fonts'

import { 
    MidWidthContainer,
    P
  } from '../styled/Maincss'

  class Megabox extends React.Component {
    constructor(props) {
      super(props)
    }
    
    render () {
      return (
        <MegaSection>
          <MidWidthContainer>
          <H2>{this.props.title}</H2>
          <P1>{ReactHtmlParser(this.props.content)}</P1>
          </MidWidthContainer>
        </MegaSection>
      )
    }
}

export default Megabox


const MegaSection = styled.section`
  background:${colors.slate};
  padding:${sizes.padding * 2}px 0;
`
const H2 = styled.h2`
  color:${colors.white};
  font-family:${fonts.escrowRegular};
  text-align:center;
  ${siasize('font-size', 32, 36, 560, 1400)};
`
const P1 = styled(P)`
  color:${colors.white};
  a {
    display:inline-block;
    color:${colors.white};
    border-bottom:1px solid ${colors.peach3};
  }
`