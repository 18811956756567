import React from "react";
import styled from 'styled-components'
import siasize from '../../mixins/siasize'

import { Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const IdeasHeroSVG = () => {
  return (
    <>
      <Controller>
        <Scene
          indicators={false}
          triggerElement="#hero"
          duration={`140%`}
          triggerHook='onLeave'
          offset={100}
        >
          {(progress, event) => {
            return (
              <Figure>
                <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 788 961" width="788" height="961">
                  <Tween
                    position="0"
                    from={{ y: 30, }}
                    to={{ y: -50, }}
                    reverse
                    paused
                    playState={
                      (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                        (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                    }
                  >
                    <path
                      fill="#616982"
                      d="M0 701.506V0h661.233c-4.33 218.793-63.487 388.653-177.472 509.58C369.777 630.507 208.523 694.482 0 701.506z"
                    ></path>
                  </Tween>
                  <Tween
                    position="0"
                    from={{ y: 50, }}
                    to={{ y: -100, }}
                    reverse
                    paused
                    playState={
                      (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                        (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                    }
                  >
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#E39970"
                      d="M770 830V128.494H108.765c4.33 218.793 63.487 388.653 177.472 509.58C400.223 759.001 561.476 822.977 769.999 830z"
                    ></path>
                  </Tween>
                </SVG>
              </Figure>
            )
          }}
        </Scene>
      </Controller>
    </>

  );
}

export default IdeasHeroSVG;

const Figure = styled.figure`
  ${siasize('width', 280, 400, 480, 1400)}
  position:relative;
`
const SVG = styled.svg`
  position: absolute;
  width: 130%;
  top: -50px;
`
