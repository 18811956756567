import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import SmallCTA from '../ui/SmallCTA'
import { SquareModSection, QuarterContainer, SquareModP } from '../styled/modules/SquareModcss'
import QuarterCircle from '../svgs/QuarterCircle'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'

import { Timeline, Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const SquareMod = ({ backgroundProp, content, linkTitle, lang, url }) => {
	return (
        <SquareModSection
            backgroundProp={backgroundProp}
        >
			<Controller>
                    <Scene
                        indicators={false}
                        triggerElement="#small-mod"
                        duration={`140%`}
                        offset={ -200}
                        triggerHook={`onCenter`}
                    >
                        {(progress, event) => {
                            return (
                                
                                <Tween
                                    from={{ scale:3.5 }}
                                    to={{ scale:1 }}
                                    reverse
                                    paused
                                    playState={
                                        
                                        (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                        (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                    }
                                >
									<QuarterContainer>
										<QuarterCircle 
											fillProp={colors.black}
										/>
									</QuarterContainer>

								</Tween>
							)
						}}
					</Scene>
				</Controller>
            <SquareModP>
                {content}
            </SquareModP>
            <SmallCTA
                linkTitle={linkTitle}
                url={url}
                colorprop={colors.white}
                lang={lang}
            />
      </SquareModSection>
        
    )
}

export default SquareMod