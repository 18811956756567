import React from 'react'
import styled from 'styled-components'
import { HeroP } from './styled/partials/heroHomecss'
import colors from '../mixins/colors'

const ThankYouNewsletter = () => {
    return (
        <Container>
            <P>Thank you!</P>
        </Container>
    )
}

export default ThankYouNewsletter

const Container = styled.div`
    margin-top: -27px;
    height:46px;
    background-color:${colors.peach2};
`
const P = styled(HeroP)`
    text-align:center;
`