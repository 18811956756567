import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import LittleArrowCTA from '../svgs/LittleArrowCTA'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'
import fonts from '../../mixins/fonts'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const BigCTA = ({ colorprop, backgroundProp, tertiaryColor, linkTitle, url }) => {
	return (
        
        <Link
            to={url}
            css={`
                font-family:${fonts.pangramBold};
                color:${colorprop};
                background:${backgroundProp};
                padding: 10px 10px 0;
                text-transform: uppercase;
                font-size: 14px;
                position: relative;
                height:30px;
                display:inline-block;
            `}
        >
            <span
                css={`
                    background-color:${tertiaryColor}
                    display: inline-block;
                    height: 40px;
                    top: 0;
                    width: 38px;
                    position: absolute;
                    left: -36px;
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                `}
            >
                <QuarterCircleBig
                    fillProp={backgroundProp}
                />
            </span>
            {linkTitle}
            <span
                css={`
                    display: inline-block;
                    width: 20px;
                    position: relative;
                    top: 2px;
                    left: 5px;
                `}
            >
                <LittleArrowCTA
                    fillProp={colorprop}
                />
            </span>
            <span
                css={`
                    display: inline-block;
                    height: 40px;
                    top: 0;
                    width: 38px;
                    position: absolute;
                    right: -38px;
                `}
            >
                <QuarterCircleBig
                    fillProp={backgroundProp}
                />
            </span>
        </Link>

    )
}

export default BigCTA
