const sizes = {
	padding:50,
	paddingpx: '50px',

	midWidth:800,
	midWidthpx:"800px",
	midWidthContent:800,
	midWidthContentpx:"800px",

	maxWidth:1100,
	maxWidthpx:'1100px',
	maxWidthContent:1100,
	maxWidthContentpx:'1100px',

	maxPercentContent:'70%',

	headerHeight: 90,
	headerHeightpx: '90px',

	margin:50,
	marginpx:'50px',

	mediumMargin:75,
	mediumMarginpx:'75px',

	bigVertMargin:150,
	bigVertMarginpx:'150px',

	footer: '900px',
	footerTablet: '700px',
}
export default sizes;
