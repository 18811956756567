import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import logo from '../react.svg';
import { Helmet } from 'react-helmet'
import Axios from 'axios'
import { 
  MainContainer, 
  MidWidthContainer, 
  VerticalSpacer, 
  AccentBar,
  MarginBreaker,
  P, D
} from './styled/Maincss'
import Header from './partials/Header'
import IdeaHero from './partials/IdeaHero'
import SocialShare from './modules/SocialShare'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import NewsletterSignup from './partials/NewsletterSignup'
import Tangle from './partials/Tangle'
import Footer from './partials/Footer'
import colors from '../mixins/colors'
import sizes from '../mixins/sizes'
import { maxW } from '../mixins/breakpoints'
import { getLanguageCode, avaliableLanguages } from '../language'


class Idea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allIdeas:[], pageInfo:{}, lang:{}, isActive:true, thisUrl:''
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    //console.log("Idea Did Mount")
    const lang = getLanguageCode()

    this.setState({lang:lang, thisUrl:window.location.href})
    let slug = this.props.match.params.slug
    //console.log("THE Slug: ", slug)

      if(slug) {
        Axios.get(`https://fair-play-api.webworldnow.net/idea/${slug}/`)
          .then((response) => {
            this.setState({pageInfo: response.data, isActive:true})
          })
          .catch((response) => {
            console.error("aOOPS: ", response)
          })
      }
  }
  componentDidUpdate(prevProps, prevState) {
      if(prevProps.location.pathname !== this.props.location.pathname) {
        const lang = getLanguageCode()
        this.setState({lang:lang})
        console.log("language changed: ", this.state.lang)
      }
  }
  render() {
    
    const p = this.state.pageInfo
    const l = this.state.lang

    console.log("P:::: ", p)
    const pageTitle = p[`page_title_${l}`]
    const pageSubheader = p[`page_subheader_${l}`]
    const pageContent = p[`page_content_${l}`]
    const seoTitle = p[`seo_title_${l}`]
    const seoDescription = p[`seo_description_${l}`]
    const ideaImage = p[`main_image`]
    const publishDate = p[`date_${l}`]
    
    return (
      <MainContainer 
        className="Ideas" 
        id="Ideas"
        isActive={this.state.isActive}
      >
       <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={`${seoDescription}`} />
          <meta name="theme-color" content="#008f68" />
          <body class="dark" />
        </Helmet>
        <Header lang={l} />
        <IdeaHero
          title={pageTitle}
          subheader={pageSubheader}
          image={ideaImage}
          publishDate={publishDate}
          author={p.author}
        />
        <MidWidthContainer 
          id="max-width-containerr"
          marginTopProp="160px"
          midwidthProp="800px"
        >
          <SocialShare  thisUrl={this.state.thisUrl} />
          <D>{ReactHtmlParser(pageContent)}</D>
        </MidWidthContainer>
        
        <NewsletterSignup
          lang={l}
        />
        
    <Footer lang={l} />
      </MainContainer>
    );
  }
}

export default Idea


