import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LogoColor from '../svgs/LogoColor'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import { 
  NewsletterContainer, 
  LeftP, 
  FormHolder,
  Input, 
  Button, 
  SVGHolder 
} from '../styled/partials/newsletterSignupcss.js'
import { newsletterContent } from '../../intl'
import { availableLanguages } from '../../language'
import { MidWidthContainer } from '../styled/Maincss';
import colors from '../../mixins/colors';
import styled from 'styled-components'
console.log("test")
export default class NewsletterSignup extends Component {
	constructor(props) {
		super(props)
		this.state = {
            tempPath: "",
			//lang:props.lang
		}
       // console.log("props in constructor: ", this.props)
        
	}
	componentDidMount() {
		
	}

	componentDidUpdate(prevProps, prevState) {

    }

    render() {
      //console.log("newsLettterCOntent: ", newsletterContent)
		  const description = newsletterContent.description[`${this.props.lang}`]
		  const placeholder = newsletterContent.placeholder[`${this.props.lang}`]
		  const ctaText = newsletterContent.ctaText[`${this.props.lang}`]

    return (
      <NewsletterContainer>
          <MidWidthContainer
            displayProp="flex"
            justifyContentProp="center"
            alignItemsProp="center"
            widthProp="1000px"
            maxWidthProp="100%"
          >
            <LeftP>{description}</LeftP>
            <FormHolder>
            <Iframe src="https://cbgrantprogram.formstack.com/forms/newsletter_signup" title="Newsletter Signup" width="600" height="400"></Iframe>
            </FormHolder>
          </MidWidthContainer>
      </NewsletterContainer>
    );
  }
}

const Iframe = styled.iframe`
  height: 40px;
  border: 0;
`

