import ReactHtmlParser from 'react-html-parser'
import SmallCTA from '../ui/SmallCTA'
import { Link } from 'react-router-dom'
import { MaxWidthContainer, VerticalSpacer, CTA } from '../styled/Maincss'
import { FullModSection, QuarterContainer, FullModP } from '../styled/modules/FullModcss'
import QuarterCircleBig from '../svgs/QuarterCircleBig'
import sizes from '../../mixins/sizes'
import colors from '../../mixins/colors'

import { Timeline, Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const FullMod = ({ backgroundProp, content, linkTitle, url, lang }) => {
	return (
        <FullModSection
            backgroundProp={backgroundProp}
        >
			<Controller>
                    <Scene
                        indicators={false}
                        triggerElement="#small-mod"
                        duration={`140%`}
                        offset={ 400}
                        triggerHook={`onCenter`}
                    >
                        {(progress, event) => {
                            return (
                                
                                <Tween
                                    from={{ scale:1.5 }}
                                    to={{ scale:1 }}
                                    reverse
                                    paused
                                    playState={
                                        
                                        (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                        (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                    }
                                >
									<QuarterContainer>
										<QuarterCircleBig 
											fillProp={colors.bergundy}
										/>
									</QuarterContainer>
								</Tween>
							)
						}}
					</Scene>
				</Controller>
            <FullModP>
                {content}
            </FullModP>
            <SmallCTA
                linkTitle={linkTitle}
                url={url}
                colorprop={colors.purple}
                lang={lang}
            />
            
      </FullModSection>
        
    )
}

export default FullMod