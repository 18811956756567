import React from "react";
import styled from 'styled-components'
import siasize from '../../mixins/siasize'
import { Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const ServicesHeroSVG = () => {
    return (
        <>
            <Controller>
                <Scene
                    indicators={false}
                    triggerElement="#hero"
                    duration={`140%`}
                    triggerHook='onLeave'
                    offset={100}
                >
                    {(progress, event) => {
                        return (
                            <Figure>
                                <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 961" width="1100" height="961">
                                    <Tween
                                        position="0"
                                        from={{ y: 30, }}
                                        to={{ y: -50, }}
                                        reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                                (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                    >
                                        <path
                                            style={{ mixBlendMode: "multiply" }}
                                            fill="#9E2A4C"
                                            d="M338 688V0h662c-4.335 214.58-63.56 381.17-177.678 499.77C708.205 618.367 546.765 681.111 338 688z"
                                        />
                                    </Tween>
                                    <Tween
                                        position="0"
                                        from={{ y: 50, }}
                                        to={{ y: -100, }}
                                        reverse
                                        paused
                                        playState={
                                            (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' :
                                                (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                        }
                                    >
                                        <path
                                            style={{ mixBlendMode: "multiply" }}
                                            fill="#616982"
                                            d="M662 116v688H0c4.335-214.58 63.56-381.17 177.678-499.77C291.795 185.633 453.235 122.889 662 116z"
                                        />
                                    </Tween>
                                </SVG>
                            </Figure>
                        )
                    }}
                </Scene>
            </Controller>
        </>
    );
}

export default ServicesHeroSVG;

const Figure = styled.figure`
  ${siasize('width', 280, 400, 600, 1400)}
  position:relative;
`
const SVG = styled.svg`
	position: absolute;
	width: 170%;
	top: -300px;
	left: -20%;
`