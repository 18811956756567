// converted to in-page styled components

import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

import styled from 'styled-components'
import colors from '../../mixins/colors'
import sizes from '../../mixins/sizes'
import fonts from '../../mixins/fonts'
import { siasize, backgroundImageFilter } from '../../mixins/globals'
import { maxW } from '../../mixins/breakpoints'

import BigCTA from '../ui/BigCTA'

export default class IdeaHero extends Component  {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render () {
		console.log("PROPS IN IDEA HERO: ", this.props)
	
		return (
			<HeroOuterContainer>
				<HeroInnerContainer donate={this.props.donate}>
					<HeroLeft>
                        <IdeaImage
							css={`
								background-image:url(${this.props.image})
							`}
							donate={this.props.donate}
                         />
					</HeroLeft>
                    <HeroRight>
                    {this.props.title &&
						<H1>{ReactHtmlParser(this.props.title)}</H1>
					}
					{this.props.subheader &&
						<P>{ReactHtmlParser(this.props.subheader)}</P>
					}
					{this.props.donate && this.props.image &&
						<CTAHolder>
							<DonateButton href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=SF7WMLWZ4X4BC&source=url' target='_blank'>{this.props.linkTitle}</DonateButton>
							/>
						</CTAHolder>
					}
					{this.props.publishDate &&
						<DateDiv>{this.props.publishDate}</DateDiv>
					}
					{this.props.author &&
						<DateDiv>{this.props.author}</DateDiv>
					}
                    </HeroRight>
                    
				</HeroInnerContainer>
			</HeroOuterContainer>
		)
	}
}

////// CSS //////

export const HeroOuterContainer = styled.section`
	min-height:340px;
	width:100%;
`
export const HeroInnerContainer = styled.div`
	width: 1060px;
	max-width: calc(100% - 50px);;
	height:100%;
	display:flex;
	align-items:${props => props.donate ? 'flex-start' : 'center'};
	color:${colors.white};
	margin: 0 auto 30px;
    padding: 200px 25px 50px;
	justify-content:space-between;
	flex-direction:${props => props.donate ? 'row-reverse' : 'row'};
	${maxW[1]} {
		flex-direction: column;
        width:calc(100% - 50px);
        padding: 25px;
    }
`
export const HeroLeft = styled.div`
    position:relative;
    width:40%;
    ${maxW[1]} {
        width:100%;
    }
`
export const HeroRight = styled.div`
	padding-left: ${props => props.donate ? '5%' : '5%'};
	padding-right: 5%;
    width: 50%;
	${maxW[1]}  {
        max-width:100%;
        width:100%;
    }
`
export const H1 = styled.h1`
	color:${colors.gray};
	font-family:${fonts.escrowRegular};
	font-weight: 300;
    font-style: normal;
    text-transform: none;
    ${siasize('font-size', 36, 42, 560, 1200)};
`
export const P = styled.p`
	color:${colors.bergundy};
	font-family:${fonts.pangramRegular};
    ${siasize('font-size', 15, 18, 560, 1200)};
`
const IdeaImage = styled.div`

	width: ${props => props.donate ? '80%' : '100%'};
	margin-left:${props => props.donate ? '20%' : '0'};
    background-color:#dddddd;
    background-size:cover;
	padding-top:${props => props.donate ? '110%' : '100%'};
	background-position: center;
	${backgroundImageFilter};
	${maxW[1]} {
		width: 100%;
		margin-left: auto;
		margin-top: 100px;
		background-size: cover;
		padding-top: 60%;
		background-position: center -90px;
	}
`
const DateDiv = styled.div`
	font-family:${fonts.pangramRegular};
	${siasize('font-size', 12, 13, 560, 1200)};
	font-weight: 500;
	color:${colors.bergundy};
`
const CTAHolder = styled.div`
	margin-left: 30px;
	margin-left: 30px;
	margin-top: 50px;
	height:50px;
`

const DonateButton = styled.a`
	padding:7px 20px 10px;
	background:${colors.bergundy};
	font-family:${fonts.escrowRegular};
	color:white;
	${siasize('font-size', 16, 22, 600, 1200)};
	transition:all .3s;
	&:hover {
		opacity:.7;
	}
`
