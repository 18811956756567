import React, { Component } from 'react'
import Graphic1 from '../svgs/Graphic1-svg.js'
import ReactHtmlParser from 'react-html-parser'


// import { TweenMax } from 'gsap';
// import '../../../scrollmagic/ScrollMagic';
// import "../../../scrollmagic/plugins/animation.gsap";
// import "../../../scrollmagic/plugins/debug.addIndicators";

// plugins/debug.addIndicators.js

// import { Controller, Scene } from 'react-scrollmagic';


import { 
	HeroOuterContainer, 
	HeroInnerContainer, 
	HeroLeft, 
	H1, 
	HeroP, 
	HeroRight
} from '../styled/partials/heroHomecss'

export default class Hero extends Component  {
	constructor(props) {
		super(props)
		this.state = {}
	}
	componentDidMount(){
		// if (typeof window !== 'undefined') {
		// 	this.controller = new ScrollMagic.Controller();
			
		// 	var scene = new ScrollMagic.Scene({
		// 		triggerElement: "#hero-inner-container"
		// 	});
		// 	scene.setTween("#hero-right", 0.5, {backgroundColor: "green", scale: 2.5}) // trigger a TweenMax.to tween
		// 	scene.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
		// 	scene.addTo(this.controller);
		// }
	}
	render () {
		//console.log("PROPS IN HERO: ", this.props)
	
		return (
			<HeroOuterContainer id="hero">
				<HeroInnerContainer id="hero-inner-container">

					<HeroLeft size={this.props.size}>
					{this.props.title &&
						<H1>{ReactHtmlParser(this.props.title)}</H1>
					}
					{this.props.subheader &&
						<HeroP>{ReactHtmlParser(this.props.subheader)}</HeroP>
					}
					</HeroLeft>

					<HeroRight id="hero-right">
						{this.props.heroImage}
					</HeroRight>

				
				</HeroInnerContainer>
			</HeroOuterContainer>
		)
	}
	
}
