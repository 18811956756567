import React from "react";
import styled from 'styled-components'
import siasize from '../../mixins/siasize'

import { Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'

const ContactHeroSVG = () => {
    return (
        <>
        <Controller>
            <Scene
                indicators={false}
                triggerElement="#hero"
                duration={`140%`}
                triggerHook='onLeave'
                offset={100}
            >
            {(progress, event) => {
                return (
                    <Figure>
                        <SVG
                            xmlns="http://www.w3.org/2000/svg"
                            width="1000"
                            height="961"
                            viewBox="0 0 788 961"
                        >
                            <Tween
                                from={{ y: 50, }}
                                to={{ y: -50, }}
                                reverse
                                paused
                                playState={
                                    (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                    (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                }
                            >
                                <g>
                                    <path
                                        style={{ mixBlendMode: "multiply" }}
                                        fill="#E39970"
                                        d="M5.231 519.928v-515h495.146c-3.243 160.624-47.54 285.324-132.895 374.1-85.354 88.777-206.105 135.744-362.251 140.9z"
                                        transform="translate(-9) rotate(179 252.804 262.428)"
                                    />
                                </g>
                            </Tween>
                            <Tween
                                position="0"
                                from={{ y: 50, }}
                                to={{ y: -100, }}
                                reverse
                                paused
                                playState={
                                    (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                    (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                }
                            >
                                <g>
                                    <path
                                        style={{ mixBlendMode: "multiply" }}
                                        fill="#9E2A4C"
                                        d="M9 1h485c-6.478 189.258-94.995 336.19-265.55 440.792C165.069 480.665 91.918 512.734 9 538V1z"
                                        transform="translate(-9)"
                                    />
                                </g>
                            </Tween>
                            <Tween
                                position="0"
                                from={{ y: 50, }}
                                to={{ y: -70, }}
                                reverse
                                paused
                                playState={
                                    (event.type === 'enter' && event.scrollDirection === 'FORWARD') ? 'play' : 
                                    (event.type === 'leave' && event.scrollDirection === 'REVERSE') ? 'reverse' : null
                                }
                            >
                                <g>
                                    <path
                                        style={{ mixBlendMode: "multiply" }}
                                        fill="#E39970"
                                        d="M167.231 606.553v-515h495.145c-3.242 160.624-47.54 285.324-132.894 374.1-85.355 88.777-206.105 135.744-362.25 140.9z"
                                        transform="translate(-9) rotate(149 414.804 349.053)"
                                    />
                                </g>
                            </Tween>
                        </SVG>
                    </Figure>
                )
            }}
            </Scene>
        </Controller>
        </>
    );
}

export default ContactHeroSVG;

const Figure = styled.figure`
  ${siasize('width', 300, 420, 480, 1400)}
  position:relative;
`
const SVG = styled.svg`
  position: absolute;
  width: 150%;
  top: -140px;
  left: -30px;
`
